@import url('https://use.typekit.net/mcy4uzs.css');
@import './spacing.css';
@import './typos.css';
@import './color.css';
@import './component.css';
@import './all-ie-only.css';

html,
body {
  font-family: 'freight-sans-pro', 'open-sans', sans-serif;
  overflow-x: hidden !important;
}
.no-br {
  white-space: nowrap;
}
span {
  &.narrow {
    /*to hide by default*/
    display: none;
  }
  &.wide {
    /*to show by default*/
    display: inline-block;
  }
}
/*temp spacer*/
.related-spacer {
  height: 65px;
}
/* ==============================
                Header
================================*/

/* header primary */

#header-main {
  &.home {
    position: fixed;
    z-index: 24;
  }
  background: transparent;
  color: var(--color-white);
  padding-top: 54px;
  position: absolute;
  transition: padding 0.3s;
  width: 100%;
  z-index: 55;

  & .navbar-toggler {
    color: var(--color-white);
    border-color: transparent;
    &.openmenu {
      margin-left: 15px;
      padding-right: 0;
    }
  }
  & .navbar-toggler-icon {
    font-size: 1.75rem;
  }

  & .navbar {
    color: white;
    background: transparent;
    & .container {
      padding: 0;
    }
  }
  & .navbar-nav .nav-item {
    position: relative;
    margin-left: 25px;
    & .nav-link {
      &:hover,
      & .show:focus {
        color: var(--text-blue) !important;
      }
    }
    &.active:after,
    &.show:after {
      color: var(--text-blue) !important;
    }
  }
  & .navbar-nav .nav-link {
    font-family: freight-sans-pro, open-sans, sans-serif;
    font-size: 0.75em;
    /* font-size: 1rem; */
    font-weight: $medium;
    line-height: 1.7em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--color-white);
    cursor: pointer;
  }
  & .bg-dark {
    background-color: transparent !important;
  }
  & .navbar-collapse {
    &.show {
      background-color: var(--primary) !important;
      padding: 15px;
      margin: 0px -15px;
    }
    &.invisible {
      visibility: unset;
      opacity: 0;
      transition: all 0.4s;
    }
  }
  &.navbar-shrink {
    & .navbar-dark .navbar-brand {
      padding-top: 5px;
      padding-bottom: 5px;
      transition: padding 0.4s;

      & img {
        transition: opacity 0.3s;
      }
    }
  }
}

.navbar-dark {
  & .navbar-brand {
    transition: all 0.4s;
    padding-left: 15px;
    & img {
      width: 155px;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
  & .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 33 33'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}

#cover-top {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
}

.openmenu {
  color: var(--color-white) !important;
  cursor: pointer;
  display: block !important;
}

.megamenu {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 1084;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  overflow: hidden;
  transition: 0.5s;

  & .mega-logo {
    margin-top: 43px;
  }
  & .closebtn {
    position: absolute;
    top: 63px;
    right: 55px;
    height: 30px;
    width: 30px;
    font-size: 34px;
    line-height: 34px;
    text-align: center;
    overflow: hidden;
    padding: 0px;
    z-index: 1090;
  }
  & a.closebtn {
    color: black !important;
    text-decoration: underline;
  }
}

.megamenu-content {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  & .links {
    position: relative;
    margin: 11vh auto;
  }
}

.menu-vertical,
.menu-vertical2 {
  & .nav-link {
    letter-spacing: 2px;
  }
}
.menu-vertical2 .nav-link {
  font-weight: $medium;
  line-height: 2.25em;
}
.social-connect {
  margin-top: 35px;
}

.megamenu-heading {
  font-size: 3em;
  font-weight: $medium;
  line-height: 1em;
  letter-spacing: 0.64px;
  color: var(--dark-navy-blue-90);
  & a {
    color: var(--dark-navy-blue-90);
    position: relative;
    &:hover {
      color: var(--arrow-turquoise);
    }
  }
}

.menu-vertical {
  color: var(--black) !important;

  & a {
    color: var(--black) !important;
    padding: 0.2rem 0rem;
    font-weight: $semibold;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: var(--hyper-blue) !important;
    }
  }
}

.menu-vertical2 {
  color: var(--black) !important;
  font-size: 1em;

  & a {
    color: var(--dark-teal) !important;
    padding: 0.1rem 0rem;
    font-weight: $semibold;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: var(--hyper-blue) !important;
    }
  }
}

/* Mega Search */

.megaSearch {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 1084;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  overflow: hidden;
  transition: 0.5s;
  & .mega-logo {
    margin-top: 43px;
    position: relative;
  }
  & .closebtn {
    position: absolute;
    top: 30px;
    right: 0;
    height: 30px;
    width: 30px;
    font-size: 34px;
    line-height: 34px;
    text-align: center;
    overflow: hidden;
    padding: 0px;
    z-index: 1090;
  }
  & a.closebtn {
    color: black !important;
    text-decoration: underline;
  }
  & .search-input {
    & .input-group {
      border-bottom: 1px solid gray;
      padding-bottom: 10px;
    }
    & label {
      font-size: 1.4rem;
      font-weight: $medium;
    }
    & input {
      font-size: 2.7rem;
      border: 0;
      font-weight: $medium;
      color: var(--black);
    }
    & button {
      padding: 20px;
      border-radius: 5px !important;
      border: solid 2px var(--dark-navy-blue-90);
      font-size: 0.875em;
      text-transform: uppercase;
      color: var(--black);
      &:hover {
        background-color: rgba(0, 14, 32, 0.9);
        border-color: rgba(0, 14, 32, 0.9);
        color: var(--color-white);
      }
      & i {
        font-size: 20px;
      }
    }
    & .input-group-append {
      display: block;
      margin: auto;
    }
  }
}

.megaSearch-content {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
/*Only on Home*/
.fixed-header-top .navbar {
  opacity: 1;
  transition: opacity 0.25s;
}
.transformation img {
  float: right;
  margin: 0 0 0 15px;
}
.on-home.partners-logos {
  & h5 {
    font-size: 1.5em;
    width: 95%;
  } 
}

/* header Second */
.header-green,
.header-green.small {
  position: relative;
  background-color: #193d41;
  background: radial-gradient(
    circle at 82% 94%,
    #09344f,
    #193d41 93%,
    #1d4043 142%
  );
  background-blend-mode: multiply;
  color: var(--color-white);
  height: 425px;
  overflow: hidden;
  width: 100%;
  padding-top: 118px;
}
.header-green.small,
.header-green-new {
  background: unset;
  background-image: radial-gradient(
    circle at 114% 107%,
    #002942,
    #005962 95%,
    #004950 136%
  );
  height: 354px;
}
.header-green.middle {
  height: 274px;
}
.header-green.middle h1 {
  font-size: 3.75em;
  font-weight: $book;
  text-align: center;
}
.header-green.middle h3 {
  text-align: center;
}
.header-green h1,
.header-green.small h1 {
  color: white;
  font-size: 4em;
  font-weight: $book;
  line-height: 1.1em;
  letter-spacing: 1px;
  margin-block-start: 0.07em;
  margin-block-end: 0.3em;
  max-width: 770px;
  position: relative;
}
.header-green {
  & h3,
  &.small h3 {
    color: white;
    font-size: 1.5em;
    font-weight: $book;
    position: relative;
  }
  & h3 {
    font-size: 1.25em;
    text-transform: unset;
    &.crumbs {
      font-size: 1.1em;
      font-weight: $medium !important;
      letter-spacing: 3px;
      text-transform: uppercase;
      position: relative;
    }
  }
}
.crumbs {
  margin-top: 56px;
}
h3.crumbs .color,
p.color,
p span.color {
  color: var(--arrow-blue);
}
/* .header-green:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://www.howtogeek.com/wp-content/uploads/2015/03/typing-on-computer-keyboard-and-phone.jpg) !important;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  opacity: 0.7;
  mix-blend-mode: soft-light;
} */

.bg-service-header {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: 50%;
  opacity: 0.9;
  mix-blend-mode: lighten;
}
.header-second-short {
  height: 370px;
}
/* Close Header */

/* ==============================
                Main
================================*/
.overflow-x-hidden {
  overflow-x: hidden;
}
.first-section {
  padding-top: 95px;
}
.first-section-py {
  padding-top: 95px;
  padding-bottom: 95px;
}
.ig-carousel,
.ig-carousel-group,
.ig-section {
  min-height: 456px;
}
.ig-home .ig-container {
  min-height: 600px;
}
/*from LESS template*/
.ig-container {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}
.ig-container .row .col h1 {
  margin-block-end: 0.75em;
  margin-block-start: 1.4em;
}
.ig-container .row .col {
  & h2,
  & h2 + p {
    margin-top: 0.5em;
  }
  & h2 {
    margin-bottom: 0.5em;
  }
}

.section-hero-img,
.fixed-aspect,
.square-hero-img {
  position: relative;
  float: right;
  width: 80%;
  & .hero-img {
    width: 100%;
  }
  & .rectangle {
    position: absolute;
    width: 28%;
    &.small {
      bottom: 19%;
      left: -18%;
      z-index: 9;
      & + .rectangle.small {
        bottom: -6%;
        left: 20%;
        z-index: -1;
        & + .rectangle.small {
          right: 15%;
          top: -12%;
          z-index: 9;
          & + .rectangle.small {
            bottom: unset;
            left: unset;
            right: -18%;
            top: 35%;
            & + .rectangle.small {
              bottom: 6%;
              right: -38%;
              top: unset;
            }
          }
        }
      }
    }
  }
}
.fixed-aspect {
  clear: both;
  width: 100%;
}
.section-hero-img{
  margin-top: -76px;
}
.section-hero-img.square {
  display: none;
}
.idea-details .square-hero-img {
  padding-top: 65px;
}
@media (max-width: 768px) {
  .section-hero-img {
    display: none;
  }
  .section-hero-img.square {
    display: block;
  }
  .square-hero-img {
    position: absolute;
  }
}
.service-img {
  position: relative;
  float: right;
  margin-top: -76px;
  width: 80%;
}

.service-img .service-image {
  width: 100%;
}
.service-img .rectangle {
  position: absolute;
  width: 28%;
}
.service-img .rectangle1 {
  left: -18%;
  bottom: 19%;
  z-index: 9;
}
.service-img .rectangle2 {
  left: 20%;
  bottom: -6%;
  z-index: -1;
}
.service-img .rectangle3 {
  right: -18%;
  top: 35%;
  z-index: -1;
}
.service-img .rectangle4 {
  right: 15%;
  top: -12%;
  z-index: 9;
}
.service-img .rectangle5 {
  right: -38%;
  bottom: 6%;
  z-index: 9;
}

.background-imgfit {
  position: absolute;
  height: 100%;
  left: 0;
  object-fit: cover;
  top: 0;
  width: 100%;
}
.continue-block {
  position: absolute;
  width: 448px;
  height: 200px;
  border: 0px;
  bottom: -50px;
  margin: -153px 248px 25px 130px;
  padding: 21px 48px 24px 48px;
  border-radius: 0.5px;
  box-shadow: 0 10px 21px 1px rgba(0, 14, 32, 0.3);
  background-color: white;
  z-index: 1;
  color: var(--primary) !important;
  z-index: 9999;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* 
@keyframes rotate  {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateReverse  {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
} */

/* external css: flickity.css */
.img-16x9 {
  max-width: 1255px;
  width: 100%;
  height: 730px;
}

/* Carousel flickity*/
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ig-carousel, 
.ig-carousel2,
.ig-careers-spotlight { /*to replace ig-carouse2*/
  & .flickity-page-dots {
    /* width: 140px; */
    width: 150px;
    bottom: -37px;
    right: 8%;
    text-align: right;
    & .dot {
      width: 9px;
      height: 9px;
      background: #ffffff;
      border-radius: 500px !important;
      opacity: 0.7;
    }
  }
  & .flickity-viewport {
    overflow: inherit;
    padding-top: .02%; /*height before img loads fix*/
    min-height: 44vw;
    & .flickity-slider {
      margin-top: -.02%
    }
    & .gallery-cell {
      padding-top: .02%; /*height before img loads fix*/
      margin-top: -.02%
    
    }
  }
  & .gallery {
    background: transparent;
  }
  & .gallery-cell {
    width: 84%;
    margin-right: 40px;
    background: transparent;
    counter-increment: gallery-cell;
    overflow: inherit;
    &.is-selected {
      overflow: inherit;
    }
    & .slide-item > img {
      display: block;
      text-align: center;
      /* content: counter(gallery-cell); //Not working iOS*/
      line-height: 200px;
      width: 100%;
      /* height: 569px; */
      object-fit: cover;
      z-index: 10;
      transform-style: flat;
    }
    & .slide-item {
      position: relative;
    }
  }
  & .gallery-title {
    margin-left: 9%;
    & h2 {
      font-weight: $medium;
    }
  }
}

.ig-approach .fixed-aspect.onebyone {
  & img.hero-img {
    z-index: 5;
  }
  & .rectangle {
    position: absolute;
    height: 110px;
    width: 110px;
    &.image {
      left: -72px;
      top: 18px;
      z-index: 4;
      & + .rectangle.image {
        left: -144px;
        top: 90px;
        z-index: 3;
        & + .rectangle.image {
          left: 65%;
          top: 88%;
          z-index: 2;
        }
      }
    }
  }
  &.right {
    & .rectangle {
      &.image {
        left: -72px;
        top: 88px;
        z-index: 4;
        & + .rectangle.image {
          left: unset;
          right: -147px;
          top: -130px;
          z-index: 3;
          & + .rectangle.image {
            left: 90%;
            top: 68%;
            z-index: 2;
          }
        }
      }
    }

  }
}

.ig-approach-amin1 {
  & .gallery-cell {
    & .slide-item > img {
      width: 105%;
      margin-left: -7px;
    }
  }
}

.ig-carousel.ig-carousel-whitebg .flickity-page-dots .dot {
  background: gray !important;
}
.ig-carousel.ig-carousel-whitebg .flickity-page-dots .dot.is-selected {
  background: rgb(0 123 255 / 0.9) !important;
}

.ig-carousel {
  & .flickity-page-dots .dot.is-selected {
    width: 28px;
    background: rgb(0 123 255 / 0.9);
  }
  &.gallery-cell {
    & .slide-item-title {
      visibility: hidden;
    }
    &.is-selected .slide-item-title {
      visibility: visible;
    }
  }
} 

.ig-carousel .flickity-prev-next-button.previous,
.ig-carousel .flickity-prev-next-button.next,
.ig-careers-spotlight .flickity-prev-next-button.previous, 
.ig-careers-spotlight .flickity-prev-next-button.next {
  display: none;
}

.ig-carousel .gallery .continue-block {
  position: absolute;
  width: 448px;
  height: 200px;
  border: 0px;
  bottom: -70px;
  margin: -153px 248px 25px 98px;
  padding: 21px 48px 24px 48px;
  border-radius: 0.5px;
  box-shadow: 0 10px 21px 1px rgba(0, 14, 32, 0.3);
  background-color: white;
  z-index: 99;
  color: var(--primary) !important;
}

.ig-carousel .gallery .continue-block h2 {
  color: var(--primary) !important;
}

.ig-carousel .gallery .continue-block h2::after {
  /* z-index: 2; suspect */
}

.ig-carousel .gallery .continue-block p {
  line-height: unset;
  color: var(--primary) !important;
}

.ig-carousel .gallery .continue-block .arrow {
  position: absolute;
  right: 20px;
  top: 43%;
  font-size: 27px;
  width: 3%;
  min-width: 13px;
}
.ig-carousel .gallery .continue-block .arrow img {
  height: inherit;
}

/* .ig-carousel2 .continue-block */
.ig-carousel2,
.ig-careers-spotlight,
.ig-approach-amin1 { /*replaces ig-carousel2*/
  & .flickity-page-dots {
    width: 100%;
    right:unset;
    text-align: center;
    & .dot {
      background: var(--gray-prime);
      &.is-selected {
        width: 28px;
        background: var(--text-blue); /* rgb(0 123 255 / 0.9); */
      }
    }
  }
  & .continue-block2 {
    position: absolute;
    cursor: default;
    width: 75%;
    border: 0px;
    /* top: 45px; */
    top: 0;
    bottom: 0px;
    left: 0px;
    padding: 66px 80px;
    z-index: 99;
    color: var(--white) !important;
    & .icon-heading {
      margin-bottom: 1.4em;
      padding-left: 6vw;
      & .img-icon {
        top: -4px;
        width: 4.5vw;
      }
    }
    & h2 {
      /* color: var(--white) !important;
      position:relative; */
      font-size: 2em;
     /*  & img {
        position:absolute;
        width: 59px;
        height: 59px;
        top:0px;
        left:0px;
        display: block;
        text-align: center;
        line-height: 200px;
        object-fit: cover;
        z-index: 10;
        transform-style: flat;          
      } */
    }
    & h2.decorated:last-of-type {
      margin-bottom: .6em;
      /* padding-top: 8px;
      padding-left: 2px; */
    }
    & h2::after {
      z-index: 2;
    }
    & h2.decorated::after {
      z-index: -1;
    }
    & p {
      line-height: 1.9em;
      color: var(--white) !important;
    }
    
  }
  & .slide-item {
    & h2:not(.decorated), & h3 {
      padding-left: 35px;
    }
    & h3 {
      color: gray;
      font-size: 1em;
    }
    & h2:not(.decorated) {
      font-weight: 600;
    }
  }
}
.ig-approach-amin1 {
  & .container-fluid {
    margin-left: -1px;
    padding-right: 0;
    padding-left: 0;
  }
    
}
.ig-approach-amin4 {
  &.ig-careers-spotlight .gallery {
    width: 106%;
    margin-left: -11px;
  }
  & .gallery-cell {
    & .slide-item > img {
      /* width: 107%;
      margin-left: -13px; */
    }
  }
  &.ig-carousel2 .slide-item h2 {
    /* padding-left: 18px; */
    font-size: 2em;
    padding-left: 5vw;
  }
}

/* .ig-carousel2, */
.ig-careers-spotlight {
  & .gallery-cell img {
    height: unset;
  }
  & .grow {
    & h2.decorated::after {
      background: var(--text-blue);
    }
    &.img-area {
      background-image: radial-gradient(circle at 119% 117%, #004950 0%, #005962 31%, #002942 103%);
    }
  }
  & .create {
    & h2.decorated::after {
      background: var(--copper);
    } 
    &.img-area {
      background-image: radial-gradient(circle at 10% 15%, #061f2f, #cb8a1e 152%);
    }
  }
  & .deliver.img-area {
  background-image: radial-gradient(circle at 107% 114%, #00624e, #00624e 23%, #004950 108%);
  }
  & .innovate {
    & h2.decorated::after {
      background: var(--salmon);
    } 
    &.img-area {
      background-image: radial-gradient(circle at 8% -32%, #061f2f, #865a55 94%);
    }
  }
}
/* //sizes above */
@media (min-width: 1450px) {
  .ig-careers-spotlight {
    & .slide-item {
      font-size: 1.1em;
    }
  }
}
/*sizes below*/
@media (max-width: 1199.98px) {
  .ig-careers-spotlight {
    & .ig-carousel2 .continue-block2, 
    & .continue-block2 {
      padding: 45px 55px;
      width: 80%;
      & .icon-heading h2.decorated {
        font-size: 2em;
        margin-right: 3px;
      }
    }
    & .slide-item {
      font-size: .8em;
      margin-right: 2px;
      & p {
        font-size: 1.1rem;
      }
    }
  }
}
@media (max-width: 991.98px) {
  .ig-careers-spotlight {
    & .ig-carousel2 .continue-block2, 
    & .continue-block2 {
      padding: 40px 47px;
      width: 80%;
      & .icon-heading {
        & h2.decorated {
          /* font-size: 2em; */
          margin-right: 2px;
        }
      }
    }
    & .slide-item {
      font-size: .8em;
      & p {
        font-size: 1rem;
        line-height: 3vw;
      }
    }
  }
}
@media (max-width: 768px) {
  .ig-careers-spotlight {
    & .flickity-viewport {
      min-height: 132vw;
    }
    & .ig-carousel2 .gallery-title,
    & .gallery-title {
      margin-left: 25px;
    }
    & .gallery-cell {
      width: 95%;
      margin-right: 30px;
    }
    &.ig-approach-amin1,
    &.ig-approach-amin4 {
      & .gallery-cell {
        width: 101%;
        margin-right: 0;
      }
      & .flickity-page-dots {
        margin-top: 0em;
      }
    }
    & .ig-carousel2 .continue-block2, 
    & .continue-block2 {
      /* padding: 33px 30px 33px 40px; */
      padding: 9vw 8vw 0 10vw;
      width: 85%;
      & .icon-heading {
        margin-bottom: 1.2em;
        min-height: 68px;
        padding-left: 14vw;
        & .img-icon {
          top: 0;
          width: 10vw;
        }
      }
    }
    & .slide-item {
      font-size: 1.1em;
      & p {
        /* font-size: 1rem; */
        font-size: 3vw;
        line-height: unset;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .ig-careers-spotlight { 
    /* & .gallery-cell {
      width: 97%;
      margin-right: 30px;
    } */
    /* background: transparent;
    counter-increment: gallery-cell;
    overflow: inherit;
    } */
    & .continue-block2 {
      /* padding: 33px 20px 33px 40px; */
      & .icon-heading {
        & h2.decorated {
          font-size: 1.7em;
          margin-right: 3px;
        }
      }
    }
    & .slide-item {
      font-size: 1em;
      & .icon-heading {
        /* padding-left: 44px; */
        & h2 {
          margin-top: 0;
          margin-bottom: .3em;
        }
        & p {
          line-height: 1.5em;
        }
      }
      & p {
        font-size: 3.4vw;
        line-height: 1.6em;
      }
    }
  }
}
@media (max-width:480px) {
.ig-careers-spotlight {
    & .slide-item {
      /* font-size: .85em; */
      & .icon-heading {
        min-height: 62px;
      }
    }
    & .continue-block2 {
      & .icon-heading {
        & h2.decorated {
          /* font-size: 1.5em; */
          font-size: 5vw;
          margin-right: 0px;
        }
      }
    }
  }
}
@media (max-width:425px) {
  .ig-careers-spotlight {
    & .slide-item {
      font-size: .85em;
      /* font-size: .775em; */
      & .icon-heading {
        min-height: 65px;
        /* padding-left: 44px; */
      }
      & p {
        line-height: 1.2em;
        font-size: 4.25vw;
      }
      
    }
  }

}




/* ig-carousel-group ****************************/
/* Group2 */
.ig-carousel-group2-title {
  margin-left: 12.5%;
  margin-right: 12.5%;
}
.ig-carousel-group2 .owl-stage-outer {
  padding-bottom: 2.5rem;
}
.ig-carousel-group2 .owl-stage {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ig-carousel-group2 .owl-item,
.ig-carousel-group3 .owl-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto !important;
  border-radius: 0px;
  background-color: var(--color-white);
  border: 0px;
}
.owl-nav button.owl-next,
.owl-nav button.owl-prev {
  border-radius: 0 !important;
  box-shadow: 4px 1px 13px 5px rgb(100 117 117 / 20%);
  font-size: 25px !important;
  height: 54px;
  margin: 0 1px 0 0;
  margin-left: 0px !important;
  padding: 0.5px 17.9px 3.5px 17.5px !important;
  width: 54px;
}
.owl-nav button.owl-prev {
  box-shadow: -4px 1px 13px 5px rgb(100 117 117 / 20%);
  margin-right: 1px !important;
}
/* .owl-nav button.owl-next {
    border-left: solid 1px #efefef;
} */
.owl-nav button.owl-prev:hover {
  box-shadow: -3px 0 12px 4.5px hsl(180deg 8% 43% / 30%);
}
.owl-nav button.owl-next:hover {
  box-shadow: 3px 0 12px 4.5px hsl(180deg 8% 43% / 30%);
}
.ig-carousel-group2 .item {
  width: 100%;
}
.ig-carousel-group2.width-unset .item {
  width: unset;
}
.ig-carousel-group2 .owl-nav button.owl-next,
.ig-carousel-group2 .owl-nav button.owl-prev,
.leadership-slider .owl-nav button.owl-next,
.leadership-slider .owl-nav button.owl-prev {
  /* margin: 0 1px 0 0; */
  /* padding: 0.5px 17.9px 3.5px 17.5px !important; */
  padding: unset;
  color: var(--arrow-blue);
  background-color: var(--color-white);
  /* box-shadow: 0 3.5px 15px 0 rgba(100, 117, 117, 0.1); */
}
/* .blue-to-green .ig-carousel-group3 .owl-nav button.owl-next,
.blue-to-green .ig-carousel-group3 .owl-nav button.owl-prev {
    box-shadow: 0 1px 11px 5px rgb(100 117 117 / 25%);
} */
.ig-carousel-group2 .owl-nav [class*='owl-']:hover,
.leadership-slider .owl-nav [class*='owl-']:hover {
  background-color: var(--color-white);
  /* border-radius: .25px; */
  /* box-shadow: 0.5px 0 2px 0.5px hsla(180, 8%, 43%, 0.7); */
  color: var(--arrow-blue);
  text-decoration: none;
}
/* Group 3 */
.ig-carousel-group2-title {
  margin-left: 12.5%;
  margin-right: 12.5%;
}
.fluid-content-left {
  margin-left: 25%;
}
.ig-carousel-group3 {
  margin-left: 10%;
}
.ig-home .owl-container,
.ig-container .owl-container {
  position: relative;
  right: -20%;
  margin-left: -20%;
}
.ig-home .owl-container .owl-nav,
.ig-approach .owl-container .owl-nav {
  /* position: relative;
    right: -20%; */
  margin-left: -60%;
}
.ig-approach {
  & hr {
    margin: 4vw 0 5.5vw 0;
  }
}
@media (max-width: 991.98px) {
  .ig-container .owl-container {
    right: unset;
    margin-left: unset;
  }
  .ig-approach .owl-container .owl-nav {
    margin-left: unset;
  }
}
.ig-carousel-group3 .owl-stage-outer {
  padding-bottom: 2.5rem;
  margin-top: -15px;
}
.ig-carousel-group3 .owl-stage {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
/* .ig-carousel-group3 .owl-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto !important;
    border-radius: 0.5px;
    background-color: var(--color-white);
    border: 0px;
} */
.ig-carousel-group3 .item {
  width: 100%;
}
.ig-carousel-group3.width-unset .item {
  width: unset;
}

.ig-carousel-group3 .owl-nav {
  padding: 45px;
  padding-top: 0px;
}
.ig-carousel-group3 .owl-nav button.owl-next,
.ig-carousel-group3 .owl-nav button.owl-prev {
  margin: 0 1px 0 0;
  /* padding: 0.5px 17.9px 3.5px 17.5px !important; */
  color: var(--arrow-blue);
  background-color: transparent;
  border: 0px;
  /* border-radius: 0; */
  border: 0;
  /* box-shadow: 0 3.5px 15px 0 rgba(100, 117, 117, 0.5); */
  /* font-size: 25px; */
}
.ig-home .ig-carousel-group3 .owl-nav button.owl-next,
.ig-home .ig-carousel-group3 .owl-nav button.owl-prev {
  /* box-shadow: 0 0 18px 0 rgb(96, 73, 74); */
  box-shadow: 0 1px 11px 5px rgb(96, 73, 74 / 25%);
}
.teal-light-to-dark .ig-carousel-group3 .owl-nav button.owl-next,
.teal-light-to-dark .ig-carousel-group3 .owl-nav button.owl-prev {
  /* box-shadow: 0 7px 30px 0 rgba(0, 187, 226, 0.3); */
  box-shadow: 0 1px 11px 5px rgb(0, 187, 226 / 25%);
}
.ig-carousel-group3 .owl-nav [class*='owl-']:hover {
  background-color: var(--color-white);
  box-shadow: 0.5px 0 2px 0.5px rgba(100, 117, 117, 0.7);
  color: var(--arrow-blue);
  text-decoration: none;
}
.ig-carousel-group3 .card-one .card-text {
  color: var(--primary);
  font-size: 1em;
  line-height: 1.3em;
}
.ig-carousel-group3 .card-one .brownish-grey-heading {
  font-size: 0.9em;
}
/*
ig-carousel-group3 .owl-item {
     min-width: 333px; 
}*/
/* Close ig-carosel-group */

/********** SOME COMMON CSS STARTS  **********/

.textItalic {
  font-style: italic;
}

.section-space {
  /* padding-left: 33px; */
  padding-bottom: 60px;
  padding-top: 60px;
  &.bottom {
    padding-top: unset;
  }
  &.top {
    padding-bottom: unset;
  }
}
/* min-height: 630px */
.section-space.slide {
  height: 100vh;
  padding: 20vh 0;
}
.section-space.slide.one h1 {
  font-size: 4em;
}
/* main {
  &.home {
    z-index: 11;
    & h1 {
      color: white;
    }
  }
} */
/* main.home h1,
main.home h2,
main.home h3,
main.home h4,
main.home h5,
main.home p {
  color: white;
} */

.tile-img-cards .tile-div {
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 2;
}
.tile-img-cards .tile-div a,
.tile-img-cards .tile-div span {
  display: block;
  height: 100%;
  box-shadow: 0 1.5em 2em 0 rgb(29 201 247 / 0.1);
  background-color: white;
  border: 0;
}
.tile-img-cards a:hover {
  box-shadow: 0 0 20.5px 0 rgba(0, 0, 0, 0.2);
}
.tile-img-cards .tile-div .tileImg {
  height: 170px;
  position: relative;
  overflow: hidden;
}
.tile-img-cards .tile-div .tileImg img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tile-img-cards .tile-div .tileData {
  padding: 30px;
}
.tile-img-cards .tile-div span h5,
.tile-img-cards .tile-div a h5 {
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 1rem;
  line-height: 1.44;
  letter-spacing: 1.89px;
  font-weight: $semibold;
}
.tile-img-cards .tile-div span p,
.tile-img-cards .tile-div a p {
  font-size: 0.9375rem;
  line-height: 1.38;
  letter-spacing: 0.5px;
}

.custom-accordion .card {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.custom-accordion .card:last-child {
  border: none;
}
.custom-accordion .card-header {
  background: transparent;
  border: 0;
  padding: 0;
}
.custom-accordion .card-header button {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 2px;
  color: #1cc4f4;
  text-decoration: none;
  width: 100%;
  text-align: left;
  padding: 1rem 1.5rem;
}
.custom-accordion .card-header button[aria-expanded='true'] {
  color: var(--black);
}
.custom-accordion .card-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.custom-accordion .card-body p,
.custom-accordion .card-body ul li {
  display: block;
  letter-spacing: 1px;
  line-height: 1.5em;
  margin-bottom: 10px;
  color: var(--black);
  font-size: 1.2em;
}
.custom-accordion .card-body ul li {
  font-weight: $medium;
  display: inline-flex;
}
.custom-accordion .card-body p.textItalic {
  font-weight: $semibold;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.5px;
}
.custom-accordion .card-body ul.square-bullets li::before {
  padding-right: 25px;
}

.custom-accordion .card-header button::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  background: url(../images/downGray.svg) no-repeat center top;
  left: 0px;
  top: 23px;
}
.custom-accordion .card-header button.collapsed::after {
  background: url(../images/upGray.svg) no-repeat center top;
  top: 22px;
}

/*************** SOME COMMON CSS ENDS *******************/
/* Home */
.ig-home {
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & p {
    color: var(--color-white);
  }
}
.section-home {
  padding-top: 100px;
  padding-bottom: 100px;
  & h1 {
    margin-bottom: 0.3em;
  }
  & p {
    margin-bottom: 2.1em;
  }
  & .ta-item-text p {
    margin-bottom: 15px;
  }
  &.section-slide {
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 100vh;
    padding: 13% 0 8%;
  }
  &.one p {
    padding-right: 3rem;
  }
  & .ig-container .row .col h1 {
    margin-top: unset;
  }
}
/* Close Home */

/* about us */
.logo-slider .owl-item {
  display: inline-block;
  margin: 0 auto;
  padding: 0 25px;
  vertical-align: middle;
}
.owl-centered .owl-wrapper {
  display: table !important;
  padding: 0 35px;
}
.owl-centered .owl-item {
  display: table-cell;
  float: none;
  vertical-align: middle;
}
.owl-centered .owl-item > div {
  text-align: center;
}

/* end about us */

/* careers */
/* .results.darkgreen-radial h2, */
.results.darkgreen-radial h3 {
  color: var(--greenblue);
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.results.darkgreen-radial p {
  padding: 5px 0 15px 0;
}
/* services overview style starts */

.services-overview {
  background-image: radial-gradient(
    circle at -6% 185%,
    #c5e2ee 0%,
    var(--color-white) 67%,
    #e7f3ee 130%
  );
}
.services-overview .rectangleImg {
  position: absolute;
  width: auto;
  top: 190px;
  right: 0;
}
/* .services-overview h1 {font-weight: 500; line-height: 1.13; letter-spacing: 0.2px;} */
.services-overview .col-lg-12 p {
  padding-bottom: 30px;
}

.services-partner {
  position: relative;
}
.services-partnerDetail img {
  width: 50%;
}
.services-partnerDetail h2 {
  width: 65%;
  line-height: 1.25;
  letter-spacing: 0.75px;
  color: var(--dark-navy-blue-90);
}
.services-partnerDetail p {
  width: 90%;
  line-height: 1.67;
  letter-spacing: 1px;
}
.services-partnerDetail button {
  padding: 15px 20px;
  background-color: transparent;
  border-radius: 5px;
  border: solid 2px var(--dark-navy-blue-90);
  text-transform: uppercase;
  font-weight: $semibold;
  letter-spacing: 2px;
  align-items: center;
  display: flex;
}
.services-partnerDetail button:hover {
  background-color: rgba(0, 14, 32, 0.9);
  border-color: rgba(0, 14, 32, 0.9);
  color: var(--color-white);
}
.services-partnerDetail button i {
  padding-left: 8px;
  font-size: 18px;
}

.services-partnerImg {
  position: relative;
}
.services-partnerImg .partner-image {
  width: 80%;
  margin-left: 4vw;
}
.services-partnerImg .rectngl,
.services-partner .rectngl {
  height: 120px;
  position: absolute;
  width: 120px;
}
.services-partnerImg .rectngl.rec1 {
  left: -95px;
  top: 10%;
}
.services-partnerImg .rectngl.rec2 {
  left: -15px;
  top: 25%;
}
.services-partnerImg .rectngl.rec3 {
  right: -15px;
  bottom: 0;
  z-index: -1;
}
.services-partner .rectngl.rec4 {
  right: -60px;
  top: 40px;
}

/* services overview style ends */

/* industry overview style starts */

/* .industry-overview {background-image: radial-gradient(circle at -6% 185%, #c5e2ee 0%, var(--color-white) 67%, #e7f3ee 130%);} */
.industry-overview h1 {
  font-weight: $medium;
  line-height: 1.13;
  letter-spacing: 0.2px;
}
/* .industry-overview .row {justify-content: center;} */
.industry-overview .col-lg-12 p {
  padding-bottom: 30px;
}

/* industry overview style ends */

/* leadership overview style starts */

.leadership-overview h1 {
  font-weight: $medium;
  line-height: 1.13;
  letter-spacing: 0.2px;
}
.leadership-overview .leadership-heading .half-body {
  max-width: 575px;
}
.leadership-overview .leadership-heading .rectngl {
  height: 100px;
  position: absolute;
  width: 100px;
}
.leadership-overview .leadership-heading .rectngl.rec1 {
  right: 0;
  top: -72%;
}
.leadership-overview .leadership-heading .rectngl.rec2 {
  right: 115px;
  top: -150px;
}
.leadership-overview .leadership-heading .rectngl.rec3 {
  right: 55px;
  top: -90px;
}
.leadership-overview .leadership-heading p {
  padding-bottom: 50px;
}
.leadership-overview .leadership-heading p span {
  font-weight: $semibold;
}
.bigImg-cards .bigImg-div {
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 2;
}
.bigImg-cards .bigImg-image {
  height: 260px;
  position: relative;
  overflow: hidden;
}
.bigImg-cards .bigImg-image img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bigImg-cards .bigImg-data {
  padding: 10px 0;
}
.bigImg-cards .bigImg-data h5 {
  text-transform: capitalize;
  font-size: 1.125rem;
  line-height: 2;
  letter-spacing: 0.63px;
}
.bigImg-cards .bigImg-data p {
  text-transform: uppercase;
  font-size: 0.9375rem;
  line-height: 1.43;
  letter-spacing: 1px;
  font-weight: $semibold;
}
.tile-tabs .nav.nav-tabs {
  border: 0;
  padding-bottom: 20px;
}
.tile-tabs .nav.nav-tabs .nav-item .nav-link {
  border: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: $semibold;
  line-height: 1.25;
  letter-spacing: 1.6px;
}
.tile-tabs .nav.nav-tabs .nav-item .nav-link.active {
  color: var(--dark-navy-blue-90) !important;
}
.tile-tabs .nav.nav-tabs .nav-item .nav-link.disabled {
  color: #cccccc !important;
}
.tile-tabs .nav.nav-tabs .nav-item .nav-link {
  border-left: 1px solid #cccccc;
  border-radius: 0;
  padding-left: 15px;
  margin-left: 15px;
  background-color: transparent;
}
.tile-tabs .nav.nav-tabs .nav-item .nav-link.fst {
  border: 0;
  margin: 0;
  padding-left: 0;
  margin-left: 0;
}

/* leadership overview style ends */

/* leadership details style starts */
.LeadershipData p span {
  font-weight: $semibold;
}

.Leadership-img {
  position: relative;
  float: right;
  margin-top: -76px;
  width: 80%;
}
.Leadership-img .Leadership-image {
  width: 100%;
  z-index: 10;
}
.Leadership-img .rectangle {
  position: absolute;
  width: 28%;
}
.Leadership-img .rectangle1 {
  left: -40%;
  top: -5%;
}
.Leadership-img .rectangle2 {
  right: -38%;
  top: 48%;
}
.Leadership-img .rectangle3 {
  right: -20%;
  top: 35%;
}
.Leadership-img .rectangle4 {
  right: 15%;
  top: -15%;
  z-index: 8;
}

.LeadershipQuote span {
  display: block;
  font-weight: $medium;
}
.LeadershipQuote p {
  max-width: 850px;
  margin: 0 auto;
}
.LeadershipQuote footer {
  letter-spacing: 1px;
}
/*???*/

.exec-image-thm {
  /* margin: 0 5px; */
  & img {
    border: solid 6px transparent;
  }
}
.leadership-slider {
  & .nav.nav-tabs {
    border: 0;
    padding-bottom: 50px;
    justify-content: center;
    & .nav-item .nav-link {
      border: 0;
      padding: 0;
      text-transform: uppercase;
      font-size: 0.9375rem;
      font-weight: $semibold;
      line-height: 1.25;
      letter-spacing: 1.6px;
      background-color: transparent;

      border-left: 1px solid #cccccc;
      border-radius: 0;
      padding-left: 15px;
      &.fst {
        border: 0;
        margin: 0;
        padding-left: 0;
        padding-right: 15px;
      }
      &.active {
        color: var(--dark-navy-blue-90) !important;
      }
    }
  }
  & .tab-content {
    & .leadershipData {
      padding: 10px 0;
      text-align: center;
      & h5 {
        text-transform: capitalize;
        font-size: 1.125rem;
        line-height: 2;
        letter-spacing: 0.63px;
      }
      & p {
        text-transform: uppercase;
        font-size: 0.9375rem;
        line-height: 1.43;
        letter-spacing: 1px;
        font-weight: $semibold;
      }
    }
    & .leadershipImg {
      height: 135px;
      position: relative;
      overflow: hidden;
      & img {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & .owl-carousel {
      & .item {
        &:hover,
        &.selected {
          & img {
            box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.6);
            border: solid 6px var(--greenblue);
          }
        }
        & a {
          display: block;
        }
        & .leadershipImg {
          margin: 0 20px;
          &  img {
            box-shadow: 0 0 11px 1px transparent;
            border: solid 6px transparent;
          }
        }
      } 
    }
  }
}

/* .leadership-slider .tab-content .leadershipData {
  padding: 10px 0;
  text-align: center;
} */
/* .leadership-slider .tab-content .leadershipData h5 {
  text-transform: capitalize;
  font-size: 1.125rem;
  line-height: 2;
  letter-spacing: 0.63px;
} */
/* .leadership-slider .tab-content .leadershipData p {
  text-transform: uppercase;
  font-size: 0.9375rem;
  line-height: 1.43;
  letter-spacing: 1px;
  font-weight: $semibold;
} */
/* .leadership-slider .nav.nav-tabs {
  border: 0;
  padding-bottom: 50px;
  justify-content: center;
} */
/* .leadership-slider .nav.nav-tabs .nav-item .nav-link {
  border: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: $semibold;
  line-height: 1.25;
  letter-spacing: 1.6px;
  background-color: transparent;
} */
/* .leadership-slider .nav.nav-tabs .nav-item .nav-link.active {
  color: var(--dark-navy-blue-90) !important;
} */
/* .leadership-slider .nav.nav-tabs .nav-item .nav-link {
  border-left: 1px solid #cccccc;
  border-radius: 0;
  padding-left: 15px;
} */
/* .leadership-slider .nav.nav-tabs .nav-item .nav-link.fst {
  border: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 15px;
} */

/* .leadership-slider .owl-nav button.owl-next,
.leadership-slider .owl-nav button.owl-prev {
    width: 70px;
    height: 70px;
    margin: 0 1px 0 0;
    padding: 0.5px 17.9px 3.5px 17.5px !important;
    color: var(--arrow-blue);
    box-shadow: 0 3.5px 15px 0 rgba(100, 117, 117, 0.1);
    background-color: var(--color-white);
    font-size: 22px;}
  
.leadership-slider .owl-nav button.owl-next span,
.leadership-slider .owl-nav button.owl-prev span {font-size: 35px;} */

/* leadership details style ends */

/* approach details style starts */

.move-forward {
  /* background-image: radial-gradient(circle at -6% 185%, #c5e2ee 0%, var(--color-white) 69%, #e7f3ee 135%); */
  background-image: radial-gradient(
    circle at -6% 185%,
    #c5e2ee 5%,
    var(--color-white) 99%,
    #e7f3ee 135%
  );
}
.move-forward .service-img .rectangle1 {
  top: -110px;
  right: 25px;
  left: auto;
}
.move-forward .service-img .rectangle2 {
  top: -30px;
  right: -55px;
  left: auto;
  z-index: 1;
}

.tilt-playing-image img {
  padding-left: 40px;
  position: absolute;
  padding-left: 40px;
  position: absolute;
  width: 150%;
  left: 0;
  margin-left: -25%;
  margin-top: -25%;
}
.tilt-playing-image ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.tilt-playing-image ul li {
  width: 100px;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: right;
  color: var(--black);
  font-weight: $semibold;
  left: 15px;
}
.tilt-playing-image ul li:first-child {
  top: 11%;
}
.tilt-playing-image ul li:nth-child(2) {
  top: 38%;
}
.tilt-playing-image ul li:last-child {
  top: 65%;
}
span.grayText {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #666666;
}
.tilt-playing-data {
  padding-top: 100px;
  padding-bottom: 50px;
}
.tilt-playing-data h2 {
  letter-spacing: 0.3px;
  color: var(--dark-navy-blue-90);
}
.tilt-playing-data p {
  letter-spacing: 1px;
  color: var(--black);
}

.move-forward .card-title {
  font-size: 1.1em;
}
.move-forward .cardList a {
  font-size: 1em;
}
.move-forward .cardList {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.move-forward .cardList li {
  font-size: 1em;
  font-weight: $semibold;
  padding: 5px 0;
  line-height: normal;
}
.move-forward-platforms > div,
.flush-width {
  margin-left: -30px;
  margin-right: -30px;
}
.balance-action {
  position: relative;
  margin-bottom: -230px;
  margin-top: 105px;
  min-height: 402px;
  min-width: 333px;
  padding: 93px;
}
.balance-action img {
  display: block;
  position: absolute; /* left: 0; top: 0; height: 100%; */
  object-fit: cover; /* background-position: 50%; width: 100%; */
}
.balance-action h2,
.balance-action p {
  text-shadow: 1px 1px 23px black;
  color: var(--color-white);
  font-size: 1.25em;
  font-weight: 400;
  margin: auto;
  text-align: center;
  width: 75%;
}
.balance-action p {
  font-size: 1.5em;
}
.balance-action p strong {
  display: block;
}
.balance-action h2 {
  font-size: 3em;
  padding-top: 50px;
}
.balance-action-spacer {
  min-height: 263px;
}

.start-with-user-heading h2 {
  line-height: 1.25;
  letter-spacing: 0.9px;
  color: var(--dark-navy-blue-90);
}
.start-with-user-heading p {
  line-height: 1.67;
  letter-spacing: 1px;
  color: var(--black);
  max-width: 830px;
}

.start-with-user-data img {
  width: 100%;
}
.start-with-user-data .rectangle {
  position: absolute;
  width: 28%;
}
.start-with-user-data .rectangle1 {
  top: 20px;
  left: 20px;
}
.start-with-user-data .rectangle2 {
  top: 290px;
  left: 100px;
}
.start-with-user-data .rectangle3 {
  top: 375px;
  left: 15px;
}
.start-with-user-data .section-space.diamond {
  padding: 125px 0 0 155px;
}

.approach-half-slide .owl-nav {
  position: absolute;
  left: auto;
  right: auto;
}
.approach-half-slide {
  padding-bottom: 100px !important;
}

.approach-about p + p,
.partner-about p + p {
  margin-bottom: 40px;
} /*May not be necessary*/

/* approach details style ends */

/* case study details style starts */
.theMiddlebg {
  background-image: radial-gradient(
    circle at -25% 155%,
    #c5e2ee 0%,
    #ffffff 60%,
    #e7f3ee 96%
  );
  background-repeat: no-repeat;
  background-position: top 600px right 100%;
  background-size: cover;
}

.casestudy-main p {
  margin-bottom: 0;
}
.casestudy-main hr {
  margin: 35px 0;
}
.casestudy-main .mainImg {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 450px;
}
.casestudy-main .imageCaption,
.ig-approach .imageCaption {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
  & p span {
    display: block;
    font-weight: $semibold;
  }
  & p {
    border-top: solid 0.5px #cccccc;
    font-size: 0.875rem;
    letter-spacing: 0.5px;
    line-height: 1.25;
    margin: 10px 21px 0 0;
    /* margin-right: 21px; */
    padding-top: 10px;
    width: 75%;
  }
}
@media (max-width: 768px) {
  .ig-approach .imageCaption {
    justify-content: flex-start;
  }
}
/* .casestudy-main .imageCaption p span {
  display: block;
  font-weight: $semibold;
} */
/* .casestudy-main .imageCaption p {
  border-top: solid 0.5px #cccccc;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  line-height: 1.25;
  margin: 10px 21px 0 0;
  padding-top: 10px;
  width: 75%;
} */
.casestudy-image {
  margin-bottom: 50px;
  margin-top: 35px;
}
.casestudy-image img {
  position: relative;
}
.situation-div h2.decorated,
.action-div h2.decorated {
  margin-left: 2.5em;
}

/*
.situation-div{margin-bottom: -30%;}
.situation-div + .action-div{padding-top: 30%;}
.situation-div + .results{margin-top: 32%;}  */

.results h1 {
  color: var(--color-white);
  margin-bottom: 30px;
  margin-top: 0;
}
.results p {
  color: var(--color-white);
  font-weight: normal;
  letter-spacing: 0.5px;
}
.results p.bullet-heading {
  font-weight: $semibold;
}
.results ul li {
  /* font-weight: 600 !important;  */
  padding-bottom: 20px;
  letter-spacing: 0.5px;
}
ul.square-bullets li::before {
  padding-right: 15px;
}

.singleSlider .singleSliderData {
  width: 60%;
  display: flex;
  padding: 15px 0;
  letter-spacing: 0.5px;
}
.singleSlider .singleSliderData span {
  display: inline-block;
  font-size: 0.875em;
  width: 20%;
}
.singleSlider .singleSliderData p {
  display: inline-block;
  font-size: 0.875em;
  margin: 0;
  line-height: 1.25;
}
.singleSlider.owl-theme .owl-dots .owl-dot span {
  height: 5px;
  width: 5px;
}
.singleSlider.owl-theme .owl-dots .owl-dot.active span {
  width: 30px;
  background-color: var(--hyper-blue);
}
.singleSlider.owl-theme .owl-dots {
  position: absolute;
  right: 0;
  bottom: 25px;
}

.counter ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.counter ul li {
  display: inline-block;
  text-align: center;
  padding-bottom: 0;
}
.counter ul li h1 {
  color: var(--greenblue);
  font-weight: $bold;
}
.counter ul li span {
  line-height: 1.11;
  letter-spacing: 1.58px;
  display: inline-block;
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: $semibold;
}
.counter.blue ul li h1 {
  color: var(--arrow-blue);
}
.counter.blue ul li span {
  color: var(--black);
}

.contribution .contribution-sectn {
  position: relative;
}
.contribution h3 {
  font-size: 1.1em;
  letter-spacing: 1.39px;
}
.contribution .contribution-sectn img {
  position: absolute;
  left: 15px;
  width: 50px;
}
.contribution .contribution-sectn p {
  padding-left: 75px;
  text-transform: uppercase;
  font-weight: $semibold;
  font-size: 1.1em;
  letter-spacing: 1.58px;
}
.contribution .contribution-sectn ul {
  padding-left: 75px;
  list-style-type: none;
}
.contribution .contribution-sectn ul li {
  padding-bottom: 10px;
  color: var(--arrow-turquoise);
  letter-spacing: 0.5px;
  font-weight: $medium;
}
.contribution .contribution-sectn ul li a {
  display: inline-block;
}
.contribution .contribution-sectn ul li i {
  padding-left: 5px;
}

.partners .container {
  border-top: 1px solid #d1d1d1;
}
.partners h3 {
  font-size: 1.1em;
  letter-spacing: 1.39px;
}
.partners p {
  letter-spacing: 0.5px;
  line-height: 1.67;
}
.partners .partners-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.partners img {
  width: 55%;
}

/* partners page new */
.partners-page {
  & .logo-slider-partner {
    & .owl-item {
      /* display: flex;
      flex-direction: column;
      align-items: flex-start; */
      vertical-align: top;
      & .item {
        /* border: solid gray 1px; */
        & img {
          padding: .5vw 0;
        }
      }
    }
  }
  & a.btn {
    &.title-gray {
      color: #7a7a7a;
      font-size: .9em;
      padding: 0;
    }
  }
  & ul.bookmarks {
    list-style: none;
    margin: 0;
    padding: 0;
    & a {
      padding-left: 0;
      text-align: left;
      width: 100%;
    }
    & li + li + li  a {
      border-top: solid #dfdfdf 1px;
    }

  }
  & h2.decorated {
    margin-top: 15px;
  }
  & .link-learn-more {
    text-align: right;
    & a.btn {
      padding: 0;
    }
  }
}
@media (min-width: 768px) {
  .partners-page {
    & ul.bookmarks {
      & li {
        float: left;
        & a {
          display: block;
          border-radius: 0px;
          font-size: .6em;
          text-align: center;
          padding: 4px 17px;
          /* text-decoration: none; */
          &.btn.title-gray {
            font-size: .6em;
            padding: 3px 0;
          }
          &:hover {
            /* background-color: #111111; */
          }
        }
        & + li {
          & a {
            border-top: solid 0px;
            border-right: solid #dfdfdf 1px;
          }
          &:last-of-type a {
            border-right: solid 0px;
          }
        }
        & + li + li  a {
          border-top: solid 0px;
        }
      }
    }
  }
}
.p-logos-2,
.p-logos-3 {
  display: none;
  & img {
    width: 100%;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  & div[class*="col"],
  & div[class*="col"] {
    margin-bottom: 3px;
    margin-top: 25px;
    padding: 5px;
    & + div[class*="col"] {
      border-left: solid #cecece 1px;
      &:last-of-type {
        border-left: solid transparent 1px;
      }
    }
  }
}
@media (max-width: 768px) {
  .on-home.partners-logos h5 {
      width: 100%;
  }
  .partners-page {
    & .link-learn-more {
    text-align: left;
  }}
  .p-logos-2 {
    display: flex;
    & img {
      /* width: 100%; */
    }
    & div[class*="col"] {
      & + div[class*="col"]:last-of-type {
        border-left: solid #cecece 1px;
      }
    }
  }
  .partners-page {
    & .space-md p {
      font-size: 1em;
      line-height: 1.5em;
    }
  }
}
@media (min-width: 769px) {
  .p-logos-3 {
    display: flex;
  }
}

/* case study  details style ends */

/* our work details style starts */

.big-rectangleDiamond {
  position: absolute;
  width: 35%;
  top: 248px;
  right: 0;
}

.work-approach,
.checkout-career {
  background-color: teal;
  overflow: hidden;
  position: relative;
}
.work-approach h1 {
  color: rgba(255, 255, 255, 0.9);
}
.work-approach p {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.67;
  letter-spacing: 0.5px;
  max-width: 525px;
}
.work-approach button {
  color: rgba(255, 255, 255, 0.9);
  background: transparent;
  border: 1px solid var(--color-white);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
}
.work-approach button:hover {
  background-color: rgba(0, 14, 32, 0.9);
  border-color: rgba(0, 14, 32, 0.9);
}
.work-approach button i {
  padding-left: 10px;
}

.imgDropdown {
  border-bottom: 1px solid #d1d1d1;
}
.imgDropdown .imgDropdown-heading {
  position: relative;
}
.imgDropdown .imgDropdown-heading {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: $semibold;
  line-height: 1.25;
  letter-spacing: 1.6px;
}
.imgDropdown .imgDropdown-heading a {
  display: block;
  color: var(--black) !important;
}
.imgDropdown .imgDropdown-heading a img {
  padding-right: 20px;
}

.imgDropdown .imgDropdown-nav {
  list-style-type: none;
  padding: 20px 0;
  background-color: var(--color-white);
  border-top: 5px solid var(--arrow-turquoise);
  position: absolute;
  z-index: 9;
  width: 65%;
  text-align: left;
  right: 0;
  display: none;
  box-shadow: 0 0 10px 2px rgb(100 117 117 / 25%);
}
.imgDropdown .imgDropdown-heading:hover .imgDropdown-nav {
  display: block;
}
.imgDropdown .imgDropdown-nav .imgDropdown-item {
  padding: 5px 0;
}
.imgDropdown .imgDropdown-nav .imgDropdown-item a {
  font-size: 1.125rem;
  color: var(--arrow-turquoise) !important;
  text-transform: initial;
  padding: 0 20px;
  line-height: initial;
  /* white-space: nowrap; */
}
.imgDropdown .imgDropdown-nav .imgDropdown-item:hover a {
  color: var(--black) !important;
}

.blog-cards { 
  & .blog-data {
    padding: 20px 0;
    & span {
      display: block;
      font-size: 0.8125rem;
      letter-spacing: 1.11px;
      text-transform: uppercase;
      font-weight: $bold;
      padding-bottom: 6px;
    }
    & h5 {
      line-height: 1.17;
      letter-spacing: 0.11px;
    }
    & p {
      font-size: 1rem;
      line-height: 1.63;
      letter-spacing: 0.13px;
    }
  }
  & .blog-div {
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 2;
  }
  & .blog-image {
    height: 260px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px 2px rgba(100, 117, 117, 0.7);
    & img {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.loadMore {
  color: var(--dark-navy-blue-90) !important;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 1.6px;
  & i {
    padding-left: 10px;
  }
}

.partner-logo {
  background-image: radial-gradient(
    circle at 28% 113%,
    #c5e2ee 0%,
    var(--color-white) 60%,
    var(--color-white) 118%
  );
  & .card-flip,
  & .card-noflip {
    min-height: 180px;
  }
  &.grid-box { 
    &.grid-box-title p {
      max-width: 820px;
    }
    & .card-content-only .card-body {
      padding: 25px;
    }
    & .card-flip-front {
      & .card-content-only .card-body {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & .card-body img {
        width: 70%;
      }
    }
    & .card-flip-back {
      & .card-body p {
        font-size: 0.875em;
      }
      & .card-body h5 {
        font-size: 1em;
      }
    }
  }
  & .microsoftLogo {
    &:hover .microsoftLogo-inner {
      border: solid 7.5px var(--text-blue);
    }
    & .microsoftLogo-inner {
      background-color: var(--color-white);
      display: block;
      border: solid 7.5px transparent;
      box-shadow: 0 0 10px 2px rgb(100 117 117 / 25%);
      position: relative;

      min-height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & i {
      position: absolute;
      right: 25px;
      font-size: 30px;
      color: var(--text-blue);
    }
    & img {
      width: 50%;
    }
  }
  & .card-content-only {
    box-shadow: 0 0 10px 2px rgb(100 117 117 / 25%);
  }
  & .card-content-only,
  & .card-image-short {
    min-height: 180px;
  }
}
/* our work details style ends */

/* contact us style starts */

.contact-form {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 26%,
      var(--color-white) 105%
    ),
    linear-gradient(to bottom, #eff3f3, #eff3f3);
  padding-bottom: 50px;
}
.contact-form .form-group input,
.contact-form .form-group textarea {
  background-color: #f0f2f7;
}
.floating-form {
  background-color: var(--color-white);
  box-shadow: 0 1.5em 2em 0 rgb(29 201 247 / 0.1);
  position: absolute;
  z-index: 9;
  top: -240px;
  right: -30%;
}
.floating-form label:not(.custom-control-label) {
  color: #a1a6bb;
  text-transform: uppercase;
  padding-left: 10px;
}
.floating-form label.custom-control-label {
  color: var(--dark-navy-blue-90);
  font-weight: $medium;
}
.floating-form input:not(.custom-control-input) {
  border: solid 0.5px #e7ebf2;
  color: var(--dark-navy-blue-90);
  font-weight: $medium;
}

.custom-control-label::before {
  border-color: var(--arrow-blue);
  border-radius: 2px !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--arrow-blue);
  background-color: var(--arrow-blue);
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--arrow-blue);
}
.custom-control-input,
.custom-control-label::before,
.custom-control-label::after {
  width: 1.2rem;
  height: 1.2rem;
  top: 0.12rem;
}

.floating-form select,
.form-control::-webkit-input-placeholder,
.form-control:-moz-placeholder,
.form-control::placeholder,
.form-control:-ms-input-placeholder {
  color: var(--dark-navy-blue-90);
  font-weight: $medium;
}
.floating-form button {
  padding: 20px;
  border-radius: 5px;
  border: solid 2px var(--dark-navy-blue-90);
  font-size: 0.875em;
  text-transform: uppercase;
}
.floating-form button:hover {
  background-color: rgba(0, 14, 32, 0.9);
  border-color: rgba(0, 14, 32, 0.9);
  color: var(--color-white);
}
.floating-form .form-button {
  align-items: center;
}
.floating-form button i {
  font-size: 20px;
}

.floating-form .form-control:focus {
  box-shadow: 0 5px 12.5px 0 rgba(58, 64, 91, 0.15);
  border: solid 0.5px var(--arrow-blue);
}

/* Floating Form -normal */
.floating-form.floating-form-normal {
  position: relative;
  top: inherit;
  right: inherit;
}
/* Close Floating Form -normal */
/* 
.checkout-career {
    background-image: url('../images/contact/contact-background.png'); background-size: cover; background-position: center; 
}
*/
.checkout-career {
  & h2 {
    color: var(--color-white);
    letter-spacing: 0.56px;
    font-weight: normal;
  }
  & a {
    color: var(--color-white) !important;
    letter-spacing: 2px;
    text-transform: uppercase;
    & i {
      padding-left: 5px;
    }
  }
}

.verify-request {
  padding-left: 80px;
  padding-top: 25px;
  & p {
    margin-bottom: 0;
    line-height: initial;
    &:first-child {
      font-weight: $semibold;
      margin-bottom: 10px;
      width: 80%;
    }
  }
}

.locations {
  background-image: radial-gradient(
    circle at 42% 151%,
    #f0f3f3,
    var(--color-white) 47%,
    #dcf0f8 162%
  );
  & .tileData a {
    height: auto !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}

/* contact us style ends */

/* Close Main */

/* ==============================
                Footer
================================*/
#footer {
  background-color: var(--black);
  min-height: 153px;
  position: relative;
}

.footer-logo {
  position: absolute;
  bottom: -122px;
  padding-bottom: 1.5rem !important;
  margin: 0px;
  z-index: 10;
  & img {
    width: 100%;
    max-width: 164px;
  }
}

.footer-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.footer-connect-btn {
  float: right;
}

.footer-navbar {
  & .nav-item {
    margin-left: 40px;
  }
  & .nav-link {
    font-size: 0.7em;
    letter-spacing: 2px;
    color: var(--color-white) !important;
    padding: 10px 0px;
    &:hover {
      color: var(--arrow-turquoise);
    }
  }
}

.footer-navbar2 .nav-item {
  margin-left: 20px;
  border-bottom: 1px solid var(--grey-light) !important;
}

.footer-navbar2 .nav-link {
  font-size: 0.7em;
  color: var(--grey-light) !important;
  padding: 1px 0px;
}

.footer-navbar2 .nav-link:hover {
  color: var(--arrow-turquoise) !important;
}

.footer-navbar2 .nav-item:hover {
  border-bottom: 2px solid var(--arrow-turquoise) !important;
}
.copyright {
  font-size: 0.95em;
  color: var(--grey-light) !important;
  margin-bottom: 3px;
  margin-top: 10px;
  text-align: right;
}
/* Close Footer */

/* ---------------- MenuCool(FloatPanel) For Animation on Scroll ---------------- */
.slideanim {
  visibility: hidden;
  visibility: visible\9;
  /*For old IE browsers IE6-8 */
}
.slideanim.slide-element {
  visibility: visible;
  animation: slide-element 1s;
}

.slideanim::after {
  /* useful when its child elements are float:left; */
  content: '';
  display: table;
  clear: both;
}

@keyframes slide-element {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.hover-animate-up {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}
.hover-animate-up:hover {
  top: -10px;
}
.hover-zoom {
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }
}
/* 
.hover-zoom:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
} */
