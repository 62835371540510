@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 

.text-primary {
color: #000e20 !important;
}

.text-secondary {
color: #002942 !important;
}

.text-black {
color: #000000 !important;
}

.text-grey-light {
color: #ececec !important;
}

.text-dark-teal {
color: #1d4043 !important;
}

.text-hyper-blue {
color: #00AADA !important;
}

.text-arrow-blue {
color: #1dc9f7 !important;
}

.text-text-blue {
color: #00aada !important;
}

.text-arrow-turquoise {
color: #00bbe2 !important;
}

.text-greenblue {
color: #1ecba0 !important;
}

.text-green {
color: #1ecba0 !important;
}

.text-pink {
color: #fb958b !important;
}

.text-salmon {
color: #fb958b !important;
}


.text-gray-prime {
color: #000e48 !important;
}

.text-color-prime {
color: #000e48 !important;
}

.text-color-white {
color: #ffffff !important;
}

.text-dark-navy-blue-90 {
color: rgba(0, 14, 32, 0.9) !important;
}

.text-brownish-grey {
color: #767676 !important;
}

.text-headline-blue {
color: #7ae3ff !important;
}

.text-arrow-dk-blue {
color: #222222 !important;
}

.text-elevation-shadow-00-dp {
color: #fafafa !important;
}

.text-brownish-grey-two {
color: #5a5a5a !important;
}

.text-brownish-grey-three {
color: #666666 !important;
}
   
  
.bg-primary {
background-color: #000e20 !important;
}

.bg-secondary {
background-color: #002942 !important;
}

.bg-black {
background-color: #000000 !important;
}

.bg-grey-light {
background-color: #ececec !important;
}

.bg-dark-teal {
background-color: #1d4043 !important;
}

.bg-hyper-blue {
background-color: #00AADA !important;
}

.bg-arrow-blue {
background-color: #1dc9f7 !important;
}

.bg-text-blue {
background-color: #00aada !important;
}

.bg-arrow-turquoise {
background-color: #00bbe2 !important;
}

.bg-greenblue {
background-color: #1ecba0 !important;
}

.bg-green {
background-color: #1ecba0 !important;
}

.bg-pink {
background-color: #fb958b !important;
}

.bg-salmon {
background-color: #fb958b !important;
}
  
a {
  color: #00aada !important;
}

h1,
h2,
h3,
h4,
h5,
p {
  color: #000e20;
}

h1.green,
h2.green,
h3.green,
h4.green,
h5.green,
p.green,
span.green {
  color: #1ecba0;
}
h2.decorated::after {
  content: "";
  background: #1ecba0;
}
h3.brownish-heading {
  color: #666666 !important;
}
h3.brownish-grey-heading {
  color: #767676 !important;
}

ul.square-bullets li::before {
  content: "\25A0";
  color: #1ecba0;
}
ul.square-bullets li::before {
  content: "\25A0";
  color: #1ecba0;
}
/*  */

ul.diomond-bullets li::before {
  content: "\25A0";
  color: #1dc9f7;
}

.bull-diomond::before {
  content: "\25A0";
  color: #1dc9f7;
}

hr {
  border-top: 1px solid #d1d1d1;
}
.hr {
  border-top: 2px solid #d1d1d1;
}
.dark-background h1,
.dark-background h2,
.dark-background h3,
.dark-background h4,
.dark-background h5,
.dark-background p,
.dark-background ul li {
  color:#ffffff;
}

.btn-outline-light {
  color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}
.btn-outline-light:hover {
  color: #212529 !important;
}
/* Close color.css */

/* ===============typos.css================ */

h1,
h2,
h3,
h4,
h5,
p {
  color: #000e20;
}
li, ul li ul, ol, ol li ol {
  color: #000e20;
}
h3.brownish-heading {
  color: #666666 !important;
}
h3.brownish-grey-heading {
  color: #767676 !important;
}
ul.diomond-bullets li::before {
  content: "\25A0";
  color: #1dc9f7;
}

.bull-diomond::before {
  content: "\25A0";
  color: #1dc9f7;
}

.dark-background h1,
.dark-background h2,
.dark-background h3,
.dark-background h4,
.dark-background h5,
.dark-background p,
.dark-background ul li {
  color:#ffffff;
}

/* close typos.css */

/* ===============Component.css================ */

.shadow-grey {
  box-shadow:0 0 10px 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-dark {
  box-shadow: 0 16px 17px 0 rgba(0, 14, 32, 0.1) !important;
}
.shadow-dark-lg {
  box-shadow:0 1.5em 2em 0 rgba(29, 201, 247, 0.1);
}
.shadow-blue {
  box-shadow: 0 16px 17px 0 rgba(225, 242, 249, 0.43) !important;
}
a.shadow-blue:hover {
  box-shadow: 0 16px 17px 0 rgba(0, 0, 0, 0.1) !important;
}
.shadow-blue-lg {
  box-shadow:0 1.5em 2em 0 rgba(29, 201, 247, 0.1);
}
a.shadow-blue-lg:hover {
  box-shadow:0 1.25em 2em 0 rgba(29, 49, 86, 0.284);
} 
/* light */

.social-navbar .nav-link {
  background-color: #ffffff;
  color: #000000 !important;
}

.social-navbar .nav-link:hover {
  background-color: #00bbe2;
  color: #ffffff !important;
}

/* Dark */
.social-navbar.social-dark .nav-link {
  background-color: #000000;
  color: #ffffff !important;
}

.social-navbar.social-dark .nav-link:hover {
  background-color: #00bbe2;
  color: #ffffff !important;
}

.accordian-primary .card-header {
  border-bottom-color: #ececec !important;
  color: #000000;
}

.accordian-primary .card-header .btn-block {
    color:  rgba(0, 14, 32, 0.9);
}

.list-one .list-group-item {
  color: #1dc9f7;
}

/* Custom Cards */
.card-one {
  background-color: #ffffff;
}
.card-one .card-title {
  color: #000e20 !important;
}
/* card two */
.card-two .card-title {
  color: #000e20 !important;
}
/* Close card two */
/* .grid-box{} */
.card-content-only, .card-image-short {
  background-color: #ffffff;
}
.card-content-only .card-title, .card-image-short .card-title {
  color: #000000;
}
.card-content-only .card-text, .card-image-short .card-text {
  color: #000000;
}
.card-content-only .card-link, .card-image-short .card-link {
  color:#00bbe2 !important;
}
.card-flip-front {
  color: #000000;
}

.card-flip-back {
  background-color:#00aada;
  color:#ffffff !important;
  
}
.card-flip-back .card, 
.card-flip-back .card-content-only .card-text, 
.card-flip-back .card-image-short .card-text,
.card-flip-back .card-content-only .card-title, 
.card-flip-back .card-image-short .card-title,
.card-flip-back .card-content-only .card-link, 
.card-flip-back .card-image-short .card-link,
.card-flip-back ul.square-bullets li::before,
.card-flip-back h1,
.card-flip-back p{
  color:#ffffff !important;
}
/* Close Flip Card */
/* Close Cards */

/* Tabbed  */

.tabbed1-vertical .nav-pills .nav-link {
  color: #1dc9f7 !important;
}
.tabbed1-vertical .nav-pills .nav-link.active,
.tabbed1-vertical .nav-pills .show > .nav-link {
  color: #000000 !important;
}
.tabbed1-vertical .nav-pills .nav-link.active::before,
.tabbed1-vertical .nav-pills .show > .nav-link::before {
  content: "\25A0";
  color: #1dc9f7;
}

/* Tabbed/Accordian */
.tabbed-accordian .ta-tabs ul li a {
  color: #1dc9f7 !important;    
}
.tabbed-accordian .ta-tabs ul li:hover a{ color: #00aada;}
.tabbed-accordian .ta-tabs ul li a.active {
    color: #ffffff !important;
}
.tabbed-accordian .ta-tabs ul li a.active:before {
  content: "\25A0";
  color: #ffffff;
}
.tabbed-accordian .ta-item::before {
  color: #1dc9f7 !important;
}
.tabbed-accordian .ta-item.active::before {
  color: #ffffff !important;
}
.tabbed-accordian .ta-item.active .ta-item-text{
  background-color:#ffffff;
  color: #000000 !important;
}
.tabbed-accordian .ta-item.active .ta-item-text h3{ 
  color: #666666;
}
.tabbed-accordian .ta-item.active .ta-item-text p{ color: #000000 !important;}


/*Close Tabbed/accordian  */

/* Close Tabbed */


/* Collage */
.collage-content .collage-label p, .collage-content .collage-label strong {
  color: #ffffff;
}
/* Close Collage */

/* CSS diamond image */
.img-diamond-frame {
    background: #00aada;
}
/* End CSS diamond image */

/*  ***********Custom button************** */
/* filled button */
/* btn-primary */
.btn.btn-primary:not(.btn-outline) {
  color: #ffffff !important;
  background-color: rgba(0, 14, 32, 0.9);
  border-color: rgba(0, 14, 32, 0.9);
}
.btn.btn-primary:not(.btn-outline) i { color: #1dc9f7 !important;}
.btn.btn-primary:not(.btn-outline).active, 
.btn.btn-primary:not(.btn-outline):active, 
.btn.btn-primary:not(.btn-outline):hover, 
.open>.btn.btn-primary:not(.btn-outline).dropdown-toggle{
  color: rgba(0, 14, 32, 0.9) !important;
  background-color: #1dc9f7;
  border-color:#1dc9f7;
}
.btn.btn-primary:not(.btn-outline):hover i { color:  rgba(0, 14, 32, 0.9) !important;}

/* btn-secondary */
.btn.btn-secondary:not(.btn-outline) {
  color: rgba(0, 14, 32, 0.9) !important;
  background-color:#ffffff;
  border-color:#ffffff;
}
.btn.btn-secondary:not(.btn-outline) i { color: #1dc9f7 !important;}
.btn.btn-secondary:not(.btn-outline).active, 
.btn.btn-secondary:not(.btn-outline):active, 
.btn.btn-secondary:not(.btn-outline):hover, 
.open>.btn.btn-secondary:not(.btn-outline).dropdown-toggle {
  color: rgba(0, 14, 32, 0.9) !important;
  background-color: #1dc9f7;
  border-color:#1dc9f7;
}
.btn.btn-secondary:not(.btn-outline):hover i { color:  rgba(0, 14, 32, 0.9) !important;}
/*  filled button */
/*  outline button */
/* btn-primary */
.btn.btn-outline-primary {
  border-color: rgba(0, 14, 32, 0.9);
  color:  rgba(0, 14, 32, 0.9) !important;
}
.btn.btn-outline-primary:not(.btn-outline) i { color: #1dc9f7 !important;}
.btn.btn-outline-primary.active, 
.btn.btn-outline-primary:active, 
.btn.btn-outline-primary:active:focus, 
.btn.btn-outline-primary:active:hover, 
.btn.btn-outline-primary:focus, 
.btn.btn-outline-primary:hover {
  border-color: rgba(0, 14, 32, 0.9);
  color:  rgba(0, 14, 32, 0.9) !important;
}
/* btn-secondary */
.btn.btn-outline-secondary {
  border-color:#ffffff;
  color:#ffffff !important;
}
.btn.btn-outline-secondary i { color: #1dc9f7 !important;}
.btn.btn-outline-secondary.active, 
.btn.btn-outline-secondary:active, 
.btn.btn-outline-secondary:active:focus, 
.btn.btn-outline-secondary:active:hover, 
.btn.btn-outline-secondary:focus, 
.btn.btn-outline-secondary:hover {
  border-color:#ffffff;
  color:#ffffff !important;
}
/* btn-link */
/* btn-link-secondary */
.btn.btn-link-secondary {
  color: rgba(0, 14, 32, 0.9) !important;
}
.btn.btn-link-secondary i { color: #1dc9f7 !important;}
.btn.btn-link-secondary.active, 
.btn.btn-link-secondary:active, 
.btn.btn-link-secondary:active:focus, 
.btn.btn-link-secondary:active:hover, 
.btn.btn-link-secondary:focus, 
.btn.btn-link-secondary:hover {
  color:#00aada !important;
}
.dropdown1 .dropdown-menu{
  background-color: #ffffff;
  border-top: 5px solid #00bbe2;
}
.dropdown1 .dropdown-menu a{
  color: #00bbe2 !important;
}
.dropdown1 .dropdown-menu a:hover{
  color: #000000 !important;
}
.dropdown1 .dropdown-menu .dropdown-item.active, .dropdown1 .dropdown-menu .dropdown-item:active {
  color:#00aada;
}
/* / Custom button */
/*  Inputs */
.inputs-primary{
  color:  rgba(0, 14, 32, 0.9);
  border:solid 0.5px #1dc9f7;
  background-color: #ffffff;
}
.inputs-primary:focus {
  border: solid 0.5px #1dc9f7;
}
.search1 .input-group-text{
  background-color: #ffffff;
  border: 1px solid #1dc9f7;
}
/*  Close Inputs */

/* CLose Component.css */


/* ===============theme.css================ */

/* ==============================
                Header
================================*/

/* header primary */
#header-main {
  color: #ffffff;
}
#header-main .navbar-toggler {
  color: #ffffff;
}
#header-main .navbar-collapse.show {
  background-color: #000e20 !important;
}
#header-main .navbar-nav .nav-link:hover,
#header-main .navbar-nav .nav-link.show:focus {
  color: #00aada !important;
}

#header-main .navbar-nav .nav-item.active:after,
#header-main .navbar-nav .nav-item.show:after {
  color: #00aada !important;
}
#header-main .navbar-nav .nav-link {
  color: #ffffff !important;
  white-space: nowrap;
}


.openmenu {
  color: #ffffff !important;
}

.megamenu {
  background-color: #ffffff;
}
.megamenu-heading {
  color:  rgba(0, 14, 32, 0.9);
}
.megamenu-heading a {
  color:  rgba(0, 14, 32, 0.9) !important;
}
.megamenu-heading a:hover {
  color: #00bbe2;
}

.menu-vertical {
  color: #000000 !important;
}

.menu-vertical a{
  color: #000000 !important;
}

.menu-vertical a:hover,
.menu-vertical a:focus {
  color: #00AADA !important;
}

.menu-vertical2 {
  color: #000000 !important;
}

.menu-vertical2 a {
  color: #1d4043 !important;
}

.menu-vertical2 a:hover,
.menu-vertical2 a:focus {
  color: #00AADA !important;
}


/* Mega Search */


.megaSearch {background-color: #ffffff;}
.megaSearch .search-input input{color: #000000;}
.megaSearch .search-input button{border: solid 2px  rgba(0, 14, 32, 0.9);color: #000000;}
.megaSearch .search-input button:hover{color: #ffffff;}



/* header Second */
.header-green, .header-green.small {
  color:#ffffff;
}
/* Close Header */

/* ==============================
              Main
================================*/
.continue-block, 
.ig-carousel .gallery .continue-block, 
.ig-carousel .gallery .continue-block h2,
.ig-carousel .gallery .continue-block p{
  color: #000e20 !important;
}


/* ig-carousel-group ****************************/
/* Group2 */ 
.ig-carousel-group2 .owl-item {
  background-color: #ffffff;
}
.ig-carousel-group2 .owl-nav button.owl-next,
.ig-carousel-group2 .owl-nav button.owl-prev,
.leadership-slider .owl-nav button.owl-next, 
.leadership-slider .owl-nav button.owl-prev
{
  color: #1dc9f7;
  background-color: #ffffff;
}
.ig-carousel-group2 .owl-nav [class*=owl-]:hover, 
.leadership-slider .owl-nav [class*=owl-]:hover 
{
  background-color: #ffffff;
  color: #1dc9f7;
}
/* Group 3 */
.ig-carousel-group3 .owl-item {
  background-color: #ffffff;
}
.ig-carousel-group3 .owl-nav button.owl-next,
.ig-carousel-group3 .owl-nav button.owl-prev {
  color: #1dc9f7;
}
.ig-carousel-group3 .owl-nav [class*=owl-]:hover {
  background-color: #ffffff;
  color: #1dc9f7;
}
.ig-carousel-group3 .card-one .card-text{
  color: #000e20;
}
.ig-carousel-group3 .card-body .card-link.fixed-bottom {
  position: absolute !important;
}
/*
ig-carousel-group3 .owl-item {
   min-width: 333px; 
}*/
/* Close ig-carosel-group */


/********** SOME COMMON CSS STARTS  **********/



.custom-accordion .card-header button{color: #1cc4f4; }
.custom-accordion .card-header button[aria-expanded="true"]{color: #000000;}
.custom-accordion .card-body p, .custom-accordion .card-body ul li{color: #000000; }


/*************** SOME COMMON CSS ENDS *******************/
/* Home */
.ig-home h1, 
.ig-home h2, 
.ig-home h3, 
.ig-home h4, 
.ig-home h5, 
.ig-home p {
color: #ffffff;
}

/* Close Home */
/* careers */
.results.darkgreen-radial h2{
  color: #1ecba0;
}
/* services overview style starts */

.services-overview {background-image: radial-gradient(circle at -6% 185%, #c5e2ee 0%, #ffffff 67%, #e7f3ee 130%);}
.services-partnerDetail h2{ color:  rgba(0, 14, 32, 0.9);}
.services-partnerDetail button{border: solid 2px  rgba(0, 14, 32, 0.9);}
.services-partnerDetail button:hover{color: #ffffff;}

/* services overview style ends */

/* leadership overview style starts */

.tile-tabs .nav.nav-tabs .nav-item .nav-link.active {color:  rgba(0, 14, 32, 0.9) !important;}

/* leadership overview style ends */



/* leadership details style starts */
.leadership-slider .tab-content .owl-carousel .owl-item:hover img{ border: solid 6px #1ecba0;}

.leadership-slider .nav.nav-tabs .nav-item .nav-link.active {color:  rgba(0, 14, 32, 0.9) !important;}


.move-forward{background-image: radial-gradient(circle at -6% 185%, #c5e2ee 0%, #ffffff 69%, #e7f3ee 135%);}
.tilt-playing-image ul li{color: #000000; }
.tilt-playing-data h2{color:  rgba(0, 14, 32, 0.9);}
.tilt-playing-data p{color: #000000;}

.balance-action h2, .balance-action p {color: #ffffff;}

.start-with-user-heading h2{color:  rgba(0, 14, 32, 0.9);}
.start-with-user-heading p{color: #000000; max-width: 830px;}


/* approach details style ends */


/* case study details style starts */
.results h1{color: #ffffff;}
.results p{color:#ffffff;}
.singleSlider.owl-theme .owl-dots .owl-dot.active span{background-color: #00AADA;}
.counter ul li h1{color: #1ecba0;}
.counter ul li span{color: #ffffff; }
.counter.blue ul li h1 {color: #1dc9f7;}
.counter.blue ul li span {color: #000000;}
.contribution .contribution-sectn ul li{color: #00bbe2; }
/* case study  details style ends */



/* our work details style starts */

.work-approach button{border:1px solid #ffffff;}
.imgDropdown .imgDropdown-heading a{display: block; color: #000000 !important;}

.imgDropdown .imgDropdown-nav{background-color: #ffffff; border-top: 5px solid #00bbe2;}        
.imgDropdown .imgDropdown-nav .imgDropdown-item a{color: #00bbe2 !important; }
.imgDropdown .imgDropdown-nav .imgDropdown-item:hover a{color: #000000 !important;}
.loadMore{color:  rgba(0, 14, 32, 0.9) !important; }
.partner-logo{background-image: radial-gradient(circle at 28% 113%, #c5e2ee 0%, #ffffff 60%, #ffffff 118%);}

.partner-logo .microsoftLogo img{width: 50%;}
.partner-logo .microsoftLogo .microsoftLogo-inner{background-color: #ffffff;}
.partner-logo .microsoftLogo:hover .microsoftLogo-inner{border: solid 7.5px #00aada;}
.partner-logo .microsoftLogo i{color: #00aada;}
/* our work details style ends */


/* contact us style starts */

.contact-form {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 26%, #ffffff 105%), linear-gradient(to bottom, #eff3f3, #eff3f3);
}
.floating-form {
  background-color: #ffffff; 
}
.floating-form label:not(.custom-control-label){color: #a1a6bb; }
.floating-form label.custom-control-label{color:  rgba(0, 14, 32, 0.9);}
.floating-form input:not(.custom-control-input){color:  rgba(0, 14, 32, 0.9);}

.custom-control-label::before{border-color: #1dc9f7;}
.custom-control-input:checked~.custom-control-label::before {border-color: #1dc9f7; background-color: #1dc9f7;}
.custom-control-input:focus:not(:checked)~.custom-control-label::before {border-color: #1dc9f7;}

.floating-form select{color:  rgba(0, 14, 32, 0.9);}
.form-control::-webkit-input-placeholder {color:  rgba(0, 14, 32, 0.9);}
.form-control:-moz-placeholder {color:  rgba(0, 14, 32, 0.9);}
.form-control::-moz-placeholder {color:  rgba(0, 14, 32, 0.9);}
.form-control::placeholder {color:  rgba(0, 14, 32, 0.9); }
.form-control:-ms-input-placeholder {color:  rgba(0, 14, 32, 0.9);}
.floating-form button{border: solid 2px  rgba(0, 14, 32, 0.9);}
.floating-form button:hover{color: #ffffff;}

.floating-form .form-control:focus{border: solid 0.5px #1dc9f7;}

.checkout-career h2{color: #ffffff; }
.checkout-career a{color: #ffffff !important; }
.locations{background-image: radial-gradient(circle at 42% 151%, #f0f3f3, #ffffff 47%, #dcf0f8 162%);}

/* contact us style ends */



/* Close Main */

/* ==============================
              Footer
================================*/
#footer {
  background-color: #000000;
}

.footer-navbar .nav-link {
  color: #ffffff !important;
}

.footer-navbar .nav-link:hover {
  color: #00bbe2;
}

.copyright {
  color: #ececec !important;
}
.footer-navbar2 .nav-item {
  border-bottom: 2px solid #ececec !important;
}

.footer-navbar2 .nav-link {
  color: #ececec !important;
}

.footer-navbar2 .nav-link:hover {
  color: #00bbe2 !important;
}

.footer-navbar2 .nav-item:hover {
  border-bottom: 2px solid #00bbe2 !important;
}

/* Close Footer */
/* close theme.css */

/* ===============media-query.css================ */

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
    /* Tabbed */
    .tabbed1-vertical .nav-pills .nav-link.active,
    .tabbed1-vertical .nav-pills .show>.nav-link {
        background: #1dc9f7 !important;
        color: #ffffff !important;
    }
.tabbed-accordian .ta-item.active .ta-item-text a{color: #ffffff !important;}
.tabbed-accordian .ta-item.active .ta-item-text a i{color: #00bbe2 !important;}
    
}

  .bg-service-header {
      /* opacity: 0.11; */
      /* mix-blend-mode: normal; */
  }
  .ig-carousel .gallery .continue-block h2,
  .ig-carousel .gallery .continue-block p {
      color:#000000 !important
  }
  
}/*Ie Close  */

/*
// IE hacks
//IE 9 and down */
.bg-service-header {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0.11)";
}

/*
// IE 10 and 11
*/

/* close media-query.css */
