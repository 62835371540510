.shadow-grey {
  box-shadow:0 0 10px 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-dark {
  box-shadow: 0 16px 17px 0 rgba(0, 14, 32, 0.1) !important;
}
.shadow-dark-lg {
  box-shadow:0 1.5em 2em 0 rgba(29, 201, 247, 0.1);
}
.card.card-one.shadow-blue {
  border: solid 1px rgba(225, 242, 249, 0.95);
}
.shadow-blue {
  /* box-shadow: 0 16px 17px 0 rgba(225, 242, 249, 0.43) !important; */
  box-shadow: 0 16px 17px 8px rgba(225, 242, 249, 0.9) !important;
}
a.shadow-blue:hover {
  /* box-shadow: 0 16px 17px 0 rgba(0, 0, 0, 0.1) !important; */
  box-shadow: 0 16px 17px 8px rgba(137, 153, 160, 0.23) !important;
}
.shadow-blue-lg {
  box-shadow:0 1.5em 2em 0 rgba(29, 201, 247, 0.1);
}
a.shadow-blue-lg:hover {
  box-shadow:0 1.25em 2em 0 rgba(29, 49, 86, 0.284);
} 
.image-blade{ position:relative;
  & .bg-blend-image{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background-position: 50%;
    opacity: 0.9;
    mix-blend-mode: lighten;
    z-index:0;
    pointer-events: none;
  }
  & .card-content-only{
    background-color:var(--greenblue-40) !important;
  }
  & h1, & .card-title, & .card-text{
    color:var(--white) !important;
  }
  & h1, & h2, & p{
    color:var(--white) !important;
    z-index:2;
  }
}
/* light */
#react-app {
  transform: scale(1);
  float: right;
}

.social-navbar {
  & .nav-item {
    margin-right: 11px;
  }
  & .nav-link {
    width: 25px;
    height: 25px;
    line-height: 15px;
    padding: 5px;
    text-align: center;
    font-size: 15px;
    border-radius: 500px;
    background-color: var(--color-white);
    color: var(--black) !important;
    &:hover {
      background-color: var(--arrow-turquoise);
      color: var(--color-white) !important;
    }
  }
}
footer .social-navbar .nav-item {
  margin-left: 11px;
  margin-right: unset;
}

/* Dark */
.social-navbar.social-dark {
  & .nav-item {
    margin-right: 11px;
  }
  & .nav-link {
    background-color: var(--black);
    color: var(--color-white) !important;
    &:hover {
      background-color: var(--arrow-turquoise);
      color: var(--color-white) !important;
    }
  }
}

.accordion-primary .card {
  border-color: transparent;
  border: 0px !important;
}

.accordian-primary .card-header { /* //TODO: confirm spelling */
  background-color: transparent;
  border-color: transparent !important;
  border-bottom-color: var(--grey-light) !important;
  padding: 0px !important;
  color: var(--black);
  position: relative;
}

/* .accordian-primary .card-header h2:before {
  content: " ";
  position: absolute;
  height: 25px;
  background-color: var(--gray-prime);
  color: var(--black) !important;
  background: var(--gray-prime);
  width: 2px;
  top: 7px;
  left: 0px;
} */

.accordian-primary .card-header .btn-block {
  padding: 0px;

  font-size:1.475em;
    font-weight: 500;    
    line-height: 1.22;
    letter-spacing: 0.64px;
    color: var(--dark-navy-blue-90);
}

.accordian-primary .card-header .btn-block:focus,
.accordian-primary .card-header .btn-block:hover {
  box-shadow: none !important;
  text-decoration: none;
}

.accordian-primary>.card:not(:last-of-type) {
  border-radius: 0px !important;
}

/* Icon Heading with content */
.icon-heading {
  padding-left: 73px;
  min-height: 28px;
  position: relative;
  /* & img.img-icon, */
  & .img-icon {
    position: absolute;
    left: 0px;
    top: 7px;
    width: 44px;
  }
}

.icon-heading-content {
  padding-left: 21px;
  & h4 {
    font-size: 1.2em;
    font-weight: $semibold;
    margin-top: 0.3em;
  }
  & p:last-of-type {
    margin-block-end: 1em;
  }
  & a {
    font-weight: $semibold;
    letter-spacing: 2px;
  }
  & .learn-more {
    margin-top: 1rem;
    display: block;
  }
}
@media (max-width: 768px) {
  .icon-heading,
  .icon-heading-content {
    /* padding-left: 58px; */
    padding-left: 45px;
  }
  .icon-heading {
    & .img-icon {
      width: 38px;
    }
  }
}
@media (max-width:405px) {
  .icon-heading,
  .icon-heading-content {
    padding-left: 48px;
  }
  .icon-heading .img-icon {
    width: 28px;
  }
}

.get-there-together {
  position: relative;
  float: right;
  width: 80%;
}

.get-there-together .display-image {
  width: 100%;
  z-index: 1;
  position: relative;
}

.get-there-together .rectangle {
  position: absolute;
  width: 28%;
}

.get-there-together .rectangle1 {
  top: -15%;
  right: 21%;
  z-index: 2;
}

.get-there-together .rectangle2 {
  top: -30%;
  right: 2%;
  z-index: 0;
}

.get-there-together .rectangle3 {
  top: -15%;
  right: -17%;
  z-index: 0;
}

.tools-box {
  position: absolute;
  z-index: 9;
  width: 90%;
  bottom: 0px;
}

.tools-box .tools-logo {
  width: 100%;
  box-shadow: 4px 14px 30px rgb(220 240 248 / 0.6);
}
.list-one .list-group-item {
  padding: .3rem 0rem;
  background-color: transparent;
  color: var(--arrow-blue);
  font-size: 1rem;
  font-weight: 500;
}

/* Custom Cards */
.card-img, .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.card-one {
  border-radius: 0px;
  background-color: var(--color-white);
  border: 0px;
  height: 100%;
  /* box-shadow: 0 16px 17px 0 rgba(225, 242, 249, 0.43) !important; */
}
.ig-carousel-group3.on-home.partners-logos {
  & a {
    cursor: default;
  }
}
a.card-one {
  width: 100%;
  display: block;
}
.card-one .card-body {
  /* padding: 20px; */
  padding: 2em;
}
.card-one .card-img-box-half {
  width: 100%;
  height: 248px;
  position: relative;
  overflow: hidden;
}
.card-one .card-img-box-half .card-img-top {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-one .card-title {
  margin: 5.5px 0 6px;
  color: var(--primary) !important;
  font-family: freight-sans-pro, open-sans, sans-serif;
  font-style: normal;
  font-weight: $semibold;
  font-size: 1.7em;
  line-height: 1.1em;
}
.card-one .card-text {
  font-size: 1em;
  margin-block-end: 0.2em;
}
/* card two */
.card-two {
  border-radius: 0px;
  background-color:transparent;
  border: 0px;
  height: 100%;
}
a.card-two {
  width: 100%;
  display: block;
}
.card-two .card-body {
  padding: 20px 0px;
}
.card-two .card-img-box-half {
  width: 100%;
  height: 260px;
  position: relative;
  overflow: hidden;
}
.card-two .card-img-box-half .card-img-top {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-two .card-title {
  margin: 5.5px 0 6px;
  color: var(--primary) !important;
  font-family: freight-sans-pro, open-sans, sans-serif;
  font-style: normal;
  font-weight: $semibold;
  font-size: 1.25em;
}
.card-two .card-text {
  font-size: 1em;
  margin-block-end: 0.2em;
}
/* Close card two */
/* .grid-box{} */
.grid-box-title p{
  max-width: 70%;
}
.card-grid-one>div {
  margin-bottom: 3em;
}
.card-content-only, .card-image-short {
  position: relative;
  border-radius: 0px;
  background-color: var(--color-white);
  border: 0px;
  height: 100%;
  min-height: 295px;
}
.card-content-only .card-body {
  padding: 30px;  
}

.card-content-only .card-title, .card-image-short .card-title {
  font-family: freight-sans-pro, open-sans, sans-serif;
  font-weight: $semibold;
  font-size: 1em;
  line-height: 1.44;
  letter-spacing: 1.89px;
  color: var(--black);
  margin-block-start: -5px;
}
.card-content-only .card-text, .card-image-short .card-text {
  font-size:1.05em;
  line-height: 1.75;
  letter-spacing: 0.5px;
  color: var(--black);
}
.card-content-only .card-link, .card-image-short .card-link {
  float: right;
  height: 26px;
  font-size: 25px;
  color:var(--arrow-turquoise) !important;
}
.card-content-only .card-link.absolute-right, .card-image-short .card-link.absolute-righ {
  position: absolute;
  right: 1em;
  bottom: 0.8em;
}
/* card-image-short */
.card-image-short .card-img-box-short{
  height: 170px;
  position: relative;
  overflow: hidden;
}
.card-image-short .card-img-box-short .card-img-top{
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-image-short .card-body {
  padding: 30px 26px;
}
/* Close card-image-short */
/* Flip Card */

.card-flip, .card-noflip {
  background-color: transparent;
  width:100%;
  height:100%;
  min-height:320px;
  perspective: 1000px;
}
.card-flip.long, .card-noflip.long {
  font-size: .9em;
  height: 465px;
}
.card-flip.long h5, .card-noflip.long h5 {
  font-size: 1.1em;
}
.card-flip.long ul, .card-noflip.long ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.card-flip.long ul li, .card-noflip.long ul li {
  font-size: 1em;
  font-weight: $semibold;
  padding: 2px 0;
}
.card-flip.long ul li i, .card-noflip.long ul li i {
  font-size: 1rem;
  padding-left: 7px;
  position: relative;
  margin-top: -5px;
  top: 2px;
}
/* .card-flip.long ul li a::after, .card-noflip.long ul li a::after {
  content: "";
} */

.card-flip-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-flip:hover .card-flip-inner {
  transform: rotateY(180deg);
}

.card-flip-front, .card-flip-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0px;
  bottom: 0px;
}

.card-flip-front {
  color: var(--black);
  z-index: 1; /* need for mac chrome bug */
}

.card-flip-back {
  background-color:var(--text-blue);
  color:var(--color-white) !important;
  transform: rotateY(180deg);
  border-radius: 0px;
  overflow: hidden;
  
}
.card-flip-back ul.square-bullets li, .card-flip-back strong { 
line-height: 1.2em; font-size:1.05em;
color: var(--color-white) !important;
}
.card-flip-back .card, 
.card-flip-back .card-content-only .card-text, 
.card-flip-back .card-image-short .card-text,
.card-flip-back .card-content-only .card-title, 
.card-flip-back .card-image-short .card-title,
.card-flip-back .card-content-only .card-link, 
.card-flip-back .card-image-short .card-link,
.card-flip-back ul.square-bullets li::before,
.card-flip-back h1,
.card-flip-back p{
  background-color:transparent;
  color:var(--color-white) !important;
}
/* Close Flip Card */
/* Close Cards */

/* Tabbed  */
.tabbed1-vertical {
  min-height: 456px;
}
.tabbed1-vertical .nav-pills {
  margin-right: 10%;
}
.tabbed1-vertical .nav-pills .nav-link {
  padding: 0.5rem 0rem;
  background-color: transparent;
  color: var(--arrow-blue) !important;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-width: 0 0 1px;
  border-radius: 0px;
}
.tabbed1-vertical .nav-pills .nav-link:last-child {
  border-bottom-width: 0;
}
.tabbed1-vertical .nav-pills .nav-link.active,
.tabbed1-vertical .nav-pills .show > .nav-link {
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: var(--black) !important;
  font-weight: $semibold;
}
.tabbed1-vertical .nav-pills .nav-link.active::before,
.tabbed1-vertical .nav-pills .show > .nav-link::before {
  content: "\25A0";
  color: var(--arrow-blue);
  display: inline-block;
  font-size: 0.8em;
  padding-bottom: 11px;
  width: 0.5em;
  height: 0.5em;
  transform: rotate(45deg);
  position: absolute;
  left: 5px;
  top: 0.44rem;
}

/* Tabbed/Accordian */
.section-home.section-slide.one h1 {
  font-size: 4.5em;
  margin-bottom: .3em;
  font-weight: 500;
}
.ig-home h1 {
  font-size: 3.5em;
}

.tabbed-accordian .ta-item.active .ta-item-text a {
    display: none;
}
.tabbed-accordian .ta-item.active .ta-item-text a.btn {
    display: block;
}
.tabbed-accordian .ta-tabs ul {
  margin: 0;
  padding: 0;
  padding-right: 25%;
}
.tabbed-accordian .ta-tabs ul li {
  border: 1px solid rgba(225, 225, 225, 0.541);
  border-width: 0 0 1px;
  border-radius: 0px;
  padding: .5rem;
}
.tabbed-accordian .ta-tabs ul li:last-child { border-bottom-width: 0;}
.tabbed-accordian .ta-tabs ul li a {
  padding: 0.5rem 0rem;
  background-color: transparent;
  color: var(--arrow-blue) !important;
  font-size: 1rem;
  font-weight: $semibold;
  letter-spacing: 2px;
  position: relative;
  white-space: nowrap;
}
.tabbed-accordian .ta-tabs ul li:hover a{ color: var(--text-blue);}
.tabbed-accordian .ta-tabs ul li a.active {
  padding: 0.5rem 1.4rem;
  background-color: transparent;
  color: var(--color-white) !important;
  font-weight: $semibold;
}
.tabbed-accordian .ta-tabs ul li a.active:before {
  content: "\25A0";
  color: var(--color-white);
  display: inline-block;
  font-size: 1.25em;
  padding-bottom: 11px;
  width: 0.5em;
  height: 0.5em;
  transform: rotate(45deg);
  position: absolute;
  left: 5px;
  top: 0.5rem;
}
.tabbed-accordian .ta-item {
  margin-bottom: 2px;
}
.tabbed-accordian .ta-item::before {
  cursor: pointer;
  padding: 0.5rem 0rem;
  font-weight: 500;
  padding: 0.5em;
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--arrow-blue) !important;
}
.tabbed-accordian .ta-item.active::before {
  color: var(--color-white) !important;
}
.tabbed-accordian .ta-item.active .ta-item-content {
  /* padding: 1em; */
  padding-left: 30px;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.tabbed-accordian .ta-item.active .ta-item-text{
  position: absolute;
  width: 76%;
  min-height: 320px;
  max-height: 260px;
  border: 0px;
  bottom:-10%;
  left:-30%;
  margin: -153px 248px 5px 118px;
  padding: 38px 45px;
  border-radius: 0.5px;
  box-shadow: 0 10px 21px 1px rgba(0, 14, 32, 0.3);
  background-color:var(--color-white);
  z-index: 59;
  color: var(--black) !important;
  overflow: hidden;    
}
.tabbed-accordian .ta-item.active .ta-item-text h3{ 
  font-size: 1em;
  line-height: 1.2;
  letter-spacing: 1px;
  color: var(--brownish-grey-three);
  font-weight: 500;
  padding-bottom: 8px;
}
.tabbed-accordian .ta-item.active .ta-item-text p{ color: var(--black) !important;}
.tabbed-accordian .ta-item.active .ta-item-text a { 
  font-size: .9em;
  font-weight: $semibold;
  letter-spacing: 2px;
  position: absolute;
  /* bottom: 35px; */
  /* margin-left: -5px; */
  margin-top: 5px;
}

.tabbed-accordian .image-box { position: relative;}

.tabbed-accordian .image-box .display-image {
  width: 100%;
  z-index: 1;
  position: relative;
}

.tabbed-accordian .image-box .rectangle {
  position: absolute;
  width: 28%;
  opacity: 0.5;
}

.tabbed-accordian .image-box .rectangle1 {
  top: 47%;
  left: -14%;
  z-index: 1;
}

.tabbed-accordian .image-box .rectangle2 {
  bottom: -18%;
  left: 15%;
  z-index: 0;
}

.tabbed-accordian .image-box .rectangle3 {
  top: 15%;
  right: -14%;
  z-index: 1;
}

@media all and (min-width: 800px) {
  .tabbed-accordian .ta-item.active .ta-item-content {
    padding-top: 0;
  }

  .tabbed-accordian .tabs-side .ta-tabs li {
    margin-bottom: 2px;
  }
}
/* 
The project specific CSS starts here
This is the minimum CSS that you will need in order for this to work
*/
.tabbed-accordian .tabbed-content .ta-tabs {
  display: none;
}
.tabbed-accordian .tabbed-content .ta-item {
  min-height: 2em;
  border: 1px solid rgba(225, 225, 225, 0.125);
  border-width: 0 0 1px;
  border-radius: 0px;
  position: relative;
}
.tabbed-accordian .tabbed-content .ta-item:last-child { border-bottom-width: 0;}
.tabbed-accordian .tabbed-content .ta-item::before {
  content: attr(data-title);
  padding-left: 30px;
}
.tabbed-accordian .tabbed-content .ta-item::after {
  content: " ";
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  background: url(../images/down.svg) no-repeat center top;
  left: 0px;
  top:13px;
}
.tabbed-accordian .tabbed-content .ta-item.active::after {
  background:url(../images/up.svg) no-repeat center top;
}
.tabbed-accordian .tabbed-content .ta-item .ta-item-content {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.tabbed-accordian .tabbed-content .ta-item.active .ta-item-content {
  opacity: 1;
  visibility: visible;
  height: auto;
}

/* .tabbed-accordian .tabbed-content.tabs-side h1, */
.tabbed-accordian .tabbed-content.tabs-side p,
.tabbed-accordian .tabbed-content.tabs-side .ta-tabs {
  width: 80%;
}
.tabbed-accordian .tabbed-content.tabs-side .ta-item-text p {
  width: 100%;
}
.tabbed-accordian .tabbed-content.tabs-side .ta-item-text strong {
  font-weight: 700;
}
@media all and (min-width: 800px) {
  .tabbed-accordian .tabbed-content .ta-tabs {
    display: block;
  }
  .tabbed-accordian .tabbed-content .ta-tabs li {
    display: inline-block;
  }
  .tabbed-accordian .tabbed-content .ta-tabs li a {
    display: block;
  }
  .tabbed-accordian .tabbed-content .ta-item {
    min-height: 0;
    border-width:0px;
  }
  .tabbed-accordian .tabbed-content .ta-item::before, 
  .tabbed-accordian .tabbed-content .ta-item::after {
    display: none;
  }
  .tabbed-accordian .tabbed-content.tabs-side .ta-tabs li {
    display: block;
  }
}

/*Close Tabbed/accordian  */

/* Close Tabbed */
/* Content Display */
.content-display1 {
  & .image-box {
    position: relative;
    width: 80%;
    transition: all .4s;
    & .display-image {
      width: 100%;
      z-index: 1;
      position: relative;
    }
    & .rectangle {
      position: absolute;
      width: 28%;
    }
    &.first {
      & img {
        padding-top: 75px;
      }
      & .rectangle1 {
        top: 27%;
        left:-18%;
        z-index: 0;
      }
      & .rectangle2 {
        top: 60%;
        right: -19%;
        z-index: 0;
      }
      & .rectangle3 {
        top:-43%;
        right: 20%;
        z-index: 0;
      }
      & .rectangle4 {
        top: -30%;
        right: -33%;
        z-index: 0;
      }
    }
    &.image-left { 
      & .rectangle1 {
        top: 8%;
        left: -14%;
        z-index: 0;
      }
      & .rectangle2 {
        top: 26%;
        left: -32%;
        z-index: 0;
      }
      & .rectangle3 {
        top: 80%;
        right: -14%;
        z-index: 1;
      }
    }
  }
}
@media (max-width: 768px) {
  .content-display1 .image-box.first img {
      padding-top: unset;
  }
}

/* Content Display */
.free-rectangle{ position: relative;}
.free-rectangle .rectangle {
  position: absolute; width: 95px;
}

.free-rectangle1{ position: relative;}
.free-rectangle1 .rectangle {position: absolute; width: 9%;}
.free-rectangle1 .rectangle1 {right: 9%; top:-140px; z-index: 0;}
.free-rectangle1 .rectangle2 {right: 15%; top:-61px; z-index: 0;}
.free-rectangle1 .rectangle3 {right: 3%; top: 61%; z-index: 0;}
.free-rectangle1 .rectangle4 {left:-1%; bottom:200px; z-index: 0;}
.free-rectangle1 .rectangle5 {left:-7%; bottom: 284px; z-index: 0;}
/* Collage */

.collage1{position:relative; margin:0 16% 15% 25%;}
.collage1-left {
  position: absolute;
  width: 50%;
  padding-top: 62%;
  top: 18%;
  left: -42%;
  z-index:2
}
.collage1-center {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin:auto;
  z-index: 1;

}
.collage1-center .collage-content .collage-label{ padding-left: 3rem;}
.collage1-right {
  position: absolute;
  width: 44%;
  padding-top: 44%;
  top: 65%;
  right: -28%;
  z-index:2
}

.collage-content {
   position:  absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   text-align: center;
   font-size: 20px;
   color: white;
   width:100%;
   box-shadow: 0 10px 21px 1px rgba(0, 14, 32, 0.3);
}
.collage-content .collage-label {
  position: absolute;
  width:100%;
  bottom: -10%;
  top: 102%;
  font-size:1rem !important;
  text-align: left;
}
.collage-content .collage-label p, .collage-content .collage-label strong {
  font-size:1rem !important;
  color: var(--color-white);
}
.collage-content .collage-img {
  display:block;
  position: absolute;
  width:100%;
  height: 100%;
  object-fit: cover;
}
.collage1 .diamond-pattern{ position: absolute; width: 28%;}
.collage1 .diamond-pattern1{ 
  width: 141%;
  left: -96%;
  top: -10%;
  z-index: 9;
}
.collage1 .diamond-pattern2{
  right: -8%;
  top: -12%;
  z-index: 1;
}
.collage1 .diamond-pattern3{ 
  right: 9%;
  top: 85%;
  z-index: 9;
}
/* Close Collage */

.logo-rows{ 
  & .logo-item{ max-width: 25%; max-height: 54px; margin-bottom: 5%;
    & img{max-height: 100%; max-width: 80%;}
  }
}
.tech-container {
  display: flex;
  flex-wrap: wrap;
  padding-right: 0;
  margin-bottom: -25px;
  & div.tech-logo {
    margin-right: 65px;
    /* margin-bottom: 25px; */
    margin-bottom: 8px;
    max-width: 25vw;
    & img {
      max-height: 85px;
    }
  }
}

/* CSS diamond image */
.btn.btn-link:focus, 
.btn.btn-link:active {
    outline: 0; 
    outline:none !important;
    box-shadow: unset;
}
.img-diamond-frame {
    background: var(--text-blue);
    border-radius: 7px;
    margin: auto;
    overflow: hidden;
    transform: rotate(45deg);
    width: 75%;
    position: relative;
    display: inline-block;
    min-width: 202px
    /* right: -16%;
    bottom: -10%; */
}
.img-diamond-frame img {
    transform: rotate(-45deg) scale(1.62);
    object-fit: cover;
    top: 0
}
/* End CSS diamond image */

/*  ***********Custom button************** */
a.btn.btn-outline-dark {
  color: black!important;
}
/* a.btn.btn-outline-dark:hover {
  color: white!important;
} */
.btn-outline-dark:hover {
    color: #fff;
    background-color: rgba(228, 228, 228, 0.5);
    border-color: #343a40;
}
.btn {
  border-radius: 6px;
  border-width: 2px;
  font-size: .9em;
  font-weight: $semibold;
  letter-spacing: 2px;
  line-height: 2.05em;
  padding: 1.3em 1.35em;
}
.btn i {
  font-size: 1rem;
  padding-left: 7px;
  position: relative;
  margin-top: -5px;
  top: 2px;
}

/* filled button */
/* btn-primary */
.btn.btn-primary:not(.btn-outline) {
  color: var(--color-white) !important;
  background-color:var(--dark-navy-blue-90);
  border-color:var(--dark-navy-blue-90);
}
.btn.btn-primary:not(.btn-outline) i { color: var(--arrow-blue) !important;}
.btn.btn-primary:not(.btn-outline).active, 
.btn.btn-primary:not(.btn-outline):active, 
.btn.btn-primary:not(.btn-outline):hover, 
.open>.btn.btn-primary:not(.btn-outline).dropdown-toggle{
  color:var(--dark-navy-blue-90) !important;
  background-color: var(--arrow-blue);
  border-color:var(--arrow-blue);
}
.btn.btn-primary:not(.btn-outline):hover i { color: var(--dark-navy-blue-90) !important;}

/* btn-secondary */
.btn.btn-secondary:not(.btn-outline) {
  color:var(--dark-navy-blue-90) !important;
  background-color:var(--color-white);
  border-color:var(--color-white);
}
.btn.btn-secondary:not(.btn-outline) i { color: var(--arrow-blue) !important;}
.btn.btn-secondary:not(.btn-outline).active, 
.btn.btn-secondary:not(.btn-outline):active, 
.btn.btn-secondary:not(.btn-outline):hover, 
.open>.btn.btn-secondary:not(.btn-outline).dropdown-toggle {
  color:var(--dark-navy-blue-90) !important;
  background-color: var(--arrow-blue);
  border-color:var(--arrow-blue);
}
.btn.btn-secondary:not(.btn-outline):hover i { color: var(--dark-navy-blue-90) !important;}
/*  filled button */
/*  outline button */
/* btn-primary */
.btn.btn-outline-primary {
  border-color:var(--dark-navy-blue-90);
  color: var(--dark-navy-blue-90) !important;
  background: 0 0;
}
.btn.btn-outline-primary:not(.btn-outline) i { color: var(--arrow-blue) !important;}
.btn.btn-outline-primary.active, 
.btn.btn-outline-primary:active, 
.btn.btn-outline-primary:active:focus, 
.btn.btn-outline-primary:active:hover, 
.btn.btn-outline-primary:focus, 
.btn.btn-outline-primary:hover {
  border-color:var(--dark-navy-blue-90);
  color: var(--dark-navy-blue-90) !important;
  background-color: rgba(228, 228, 228, 0.5);
}
/* btn-secondary */
.btn.btn-outline-secondary {
  border-color:var(--color-white);
  color:var(--color-white) !important;
  background: 0 0;
  padding: 1.25em 1.75em;
}
.btn.btn-outline-secondary i { color: var(--arrow-blue) !important;}
.btn.btn-outline-secondary.active, 
.btn.btn-outline-secondary:active, 
.btn.btn-outline-secondary:active:focus, 
.btn.btn-outline-secondary:active:hover, 
.btn.btn-outline-secondary:focus, 
.btn.btn-outline-secondary:hover {
  border-color:var(--color-white);
  color:var(--color-white) !important;
  background-color: rgba(255, 255, 255, 0.15);
}
/* btn-link */
/* btn-link-secondary */
.btn.btn-link-secondary {
  border-color:transparent;
  color:var(--dark-navy-blue-90) !important;
  background: 0 0;
  font-size: 0.9375rem;
  font-weight: $semibold;
  line-height: 1.25;
  letter-spacing: 1.6px;
}
.btn.btn-link-secondary i { color: var(--arrow-blue) !important;}
.btn.btn-link-secondary.active, 
.btn.btn-link-secondary:active, 
.btn.btn-link-secondary:active:focus, 
.btn.btn-link-secondary:active:hover, 
.btn.btn-link-secondary:focus, 
.btn.btn-link-secondary:hover {
  border-color:transparent;
  color:var(--text-blue) !important;
  background-color:transparent;
  box-shadow: none;
}
.dropdown1 .dropdown-menu{
  padding: 20px 0;
  background-color: var(--color-white);
  border-top: 5px solid var(--arrow-turquoise);
  box-shadow: 0 0 10px 2px rgb(100 117 117 / 25%);
}
.dropdown1 .dropdown-menu a{
  font-size: 1.125rem;
  color: var(--arrow-turquoise) !important;
  text-transform: capitalize;
  padding: 5px 20px;
  line-height: initial;
  font-weight: $semibold;
  letter-spacing: 1.6px;
}
.dropdown1 .dropdown-menu a:hover{
  color: var(--black) !important;
}
.dropdown1 .dropdown-menu .dropdown-item:focus, .dropdown1 .dropdown-menu .dropdown-item:hover {background-color: transparent;}
.dropdown1 .dropdown-menu .dropdown-item.active, .dropdown1 .dropdown-menu .dropdown-item:active {
  color:var(--text-blue);
  background-color: #f8f9fa;
}
/* / Custom button */
/*  Inputs */
.inputs-primary{
  font-size:1.2em;
  letter-spacing: 0.32px;
  color: var(--dark-navy-blue-90);
  /* padding: 8px 7.5px 8px 10.5px; */
  border-radius: 1px;
  border:solid 0.5px var(--arrow-blue);
  background-color: var(--white);
}
.inputs-primary:focus {
  box-shadow: 0 5px 12.5px 0 rgb(58 64 91 / 15%);
  border: solid 0.5px var(--arrow-blue);
}
.search1 .input-group-text{
  position: absolute;
  right: -1px;
  height: 100%;
  background-color: var(--white);
  border: 1px solid var(--arrow-blue);
  border-left: 0;
  border-radius:1px;
  z-index: 10;
}
.search1 .inputs-primary{
  border-right: 0;
  padding-right: 18%;
}
/*  Close Inputs */

/* Approach Stacks */
ul.tabs  {
  /* display: flex; */
  display: inline-flex;
  list-style-type: none;
  padding: 0;
  & li {
    float: left;
    font-size: 1.2em;
    font-weight: $semibold; 
    margin-top: 20px;
    padding-right: 32px;
    & a {
      padding-bottom: 15px;
      white-space: nowrap;
      & img {  
        margin-right: 6px;
      }
    }
    &.active a {
      border-bottom: solid 1px #aaa;
      display: inline-block;
      color: black;
      pointer-events: none;
    }
  }
  &::after {
    clear: both;
  }
}

@media (max-width: 990px) { 
  ul.tabs  {
    /* width: 75%; */
    & li {
    }
  }
}
.anim-container {
  position: relative;
  @mixin noselect;
  /* & .flickity-page-dots {
    /* display: block; * /
    height: 35px;
    padding-right: 20px;
    position: relative;
    z-index: 24;
    & .dot.selected {
      background: var(--arrow-blue);
      opacity: 1;
    }
  } */
}

.border-tored {
  display: flex;
  align-items: center;
  /* width: 50vw; */
  /* width: 90%; */
  margin: auto;
  max-width: 22em;

  position: relative;
  padding: 30% 2em;
  box-sizing: border-box;

  border: 5px;
  /* color: #FFF; */
  /* background: #000; */
  background-clip: padding-box; /* !importanté */
  /* border: solid 5px transparent; !importanté */
  /* border-radius: 1em; */

  border-width: 3px;
  border-style: solid;
  border-image: 
    /* linear-gradient(
      to bottom, 
      red, 
      rgba(0, 0, 0, 0)
    ) 1 100%; */
    linear-gradient(to right, red, orange);
    width: 33vw;

  &:before p {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -5px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(to right, red, orange);
  }
}
.first-stack-group {
  & .idea-details {
    padding-top: 0!important;
  }
  & .stacks .stack div {
    border-color: white;
  }
}
.stacks {
  font-size: 1em;
  & h2 {
    font-size: 1.75em;
    font-weight: $semibold;
    margin-bottom: .5em;
    margin-top: .15em;
  }
  & h3 {
    color: gray;
    font-size: .9em;
  }
  & p, & li {
    font-size: .85rem;
    margin-top: 20px;
  }
  & ul {
    list-style-type: none;
    padding: 0;
    & li {
      /* display: inline-block; */
      float: left;
      margin-top: 20px;
      width: 13%;
      &:nth-child(1) {
        font-size: 1.25em;
        font-weight: 700;
        line-height: 1em;
      }
      & + li {
        width: 85%;
      }
      & span, &::after {
        display: block;
      }
    }
  }
  & .stack {
    /* border-bottom: solid 5px white; */
    display: block;
    &.blue {
      background-image: radial-gradient(circle at 0 10%, #182435, #00bbe2 100%, #182435 144%);
    }
    &.red {
      background-image: radial-gradient(circle at 0 10%, #182435, #fb958b 109%, #182435 144%);
    }
    &.green {
      background-image: radial-gradient(circle at 0 10%, #182435, #1ecba0 100%, #182435 144%);
    }
    & div {
      border-top: solid 5px #E1F0F6;
      color: white;
      display: block;
      letter-spacing: 2px;
      line-height: 111px;
      height: 115px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      vertical-align: middle;
      /* & div > div {
        border-top: solid 5px white;
      } */
    }
    & span {
      display: inline-block;
      letter-spacing: 2px;
      line-height: 111px;
      /* text-align: center; */
      /* text-transform: uppercase; */
      vertical-align: middle
    }
  }
  & div.mask {
    background: white;
    opacity: .7;
    position: absolute;
  }
  & div.number {
    background: var(--pink);
    border-radius: 50%;
    border: unset;
    font-size: 1.25em;
    font-weight: 500;
    color: var(--white);
    height: 45px;
    width: 45px; 
    line-height: 42px;
    margin: 11px;
    position: absolute;
    z-index: 23;
    &.blue {
    }
  }
  & .stack.blue .number {
    background: var(--hyper-blue);
  }
  & .stack.green .number {
    background: var(--greenblue);
  }
}

.idea-details {
  & .stacks {
    & h2 {
      font-size: 1.5em;
      letter-spacing: 0;
    }
    & .number {
      &.one {
        background: var(--text-blue);
      }
      &.three {
        background: var(--greenblue);
      }
      &.four {
        background: var(--gold);
      }
    }
  }
  & .anim-container {

  }
}

svg {
  &.dia-green {
    margin-top: 95px;
    & path {
      opacity: 0.75;
      &.green {
        opacity: 1;
      } 
    }
  }
  &#svg-lines {
    margin-top: -3px;
    position: absolute;
    width: 102.5%;
    z-index: 22;
    
    & path {
      fill: none;
      /* stroke: black; */
      stroke-width: 2px;
      &.line-1 {
        stroke: url(#grad);
      }
      &.line-2 {
        stroke: url(#grad);
      }
      &.line-3 {
        stroke: url(#grad3);
      }
    }
  }
}

.idea-details {
  & .lines-anim {
    padding-bottom: 12px;
    & h2 {
      /* margin: 0 auto; */
      text-align: center;
      /* width: 80%; */
    }
  }
  & svg#svg-lines {
    margin-left: -10px;
    /* margin-top: -31px; */
    width: 100%;
    & path {
      &.line-2 {
        stroke: url(#grad2);
      }
      &.line-3 {
        stroke: url(#grad3);
      }
    }
  }
  & .stats {
    /* & li:first-child {
      color: var(--pink);
    } */
  }
}

.stacks ul.stats {
  opacity: .05;
  &.show {
    opacity: 1;
    & li {
      color: black;
      width: 100%;
    }
  }
}
.number {
  opacity: 0;
}

.ig-approach {
  & .move-forward-no-top {
    background-image: radial-gradient(circle at -146% 195%, #c5e2ee 40%, var(--color-white) 69%, #fff 115%);
  }
  & .ig-container .row .col h1 {
    margin-top: .8em;
  }
  & .fixed-aspect.onebyone {
    overflow: unset;
  }
  & .image-pad-y {
    margin: 22px 0 42px;
  }
  & .row-dots {
    min-height: 35px;
  }
  & .flickity-page-dots {
    /* display: block; */
    height: 35px;
    /* padding-right: 20px; */
    margin-top: -20px;
    position: relative;
    z-index: 24;
    & .dot.selected {
      background: var(--arrow-blue);
      opacity: 1;
    }
  }
  &.idea-details {
    /* & .flickity-page-dots {
      margin-top: -50px;
    } */
    & .stacks p.fixed-height {
      min-height: 5.1em;
    }
    & .circle-img {
      position: absolute;
      width: 100%;
      &::after {
        clear: both;
      }
    }
    & .container.first-stack-group img {
      /* margin-left: -30vw; */
      margin-left: -3vw;
      width: 105%;
    }
  }
  & .icon-heading-content {
    margin-top: -30px;
    & p{
      padding-right: 15px;
    }
    
    & h3 {
      color: gray;
      font-size: .9em;
    }
    & .tabs li {
      margin-top: .2em;
      width: unset;
      & span {
        color: var(--arrow-blue);
        font-size: 1.3em;
        padding-bottom: 15px;
      }
    }
    & p {
      font-size: 1em;
    }
  }
}
.for-mob,
.for-mobanim-one {
  display: none!important;
}
.notmobile {
  display: block!important;
  &.flex {
    display: flex!important;
  }
}
.image-left {
  padding-right: 10%;
}
.image-right {
  padding-left: 10%;
}

@media (min-width: 1450px) {
  svg#svg-lines {
    margin-top: 0;
    width: 102%;
  }
  .stacks {
    font-size: 1.2em;
    & .stack div {
      line-height: 128px;
      height: 130px;
    }
    & div.number {
      font-size: 1.55em;
      line-height: 50px;
      height: 55px;
      width: 55px;
    }
  }
  .idea-details {
    & svg#svg-lines {
      margin-top: -14px;
      width: 102%;
    }
    & .stacks {
      & h2 {
        font-size: 1.25em;
      }
    }
  }
  .tech-container {
    & div.tech-logo {
      margin-right: 75px;
      margin-bottom: 10px;
      max-width: 27vw;
      & img {
        max-height: 105px;
      }
    }
  }
}
@media (max-width: 1449px) {
  .idea-details {
    & svg#svg-lines {
      width: 103%;
    }
  }
}
@media (max-width: 1199px) {
  svg#svg-lines {
    margin-top: 19px;
    margin-left: 6px;
    @noselect;
  }
  .idea-details {
    & svg#svg-lines {
      margin-top: 4px;
      width: 102%;
      margin-left: -4px;
    }
    & .stacks h2 {
      font-size: 1.35em;
    }
  }
  .stacks {
    /* font-size: 1.2em; */
    & .stack div {
      line-height: 97px;
      height: 99px;
    }
    & div.number {
      /* font-size: 1.55em; */
      line-height: 41px;
      height: 45px;
      width: 45px;
    }/*  */
  }
  .tech-container {
    & div.tech-logo {
      margin-right: 65px;
      margin-bottom: 7px;
      max-width: 23vw;
      & img {
        max-height: 72px;
      }
    }
  }
}
@media (max-width: 990px) {
  ul.tabs li {
    /* width: 65%; */
  }
  svg#svg-lines {
    margin-top: 68px;
    margin-left: 5px;
    width: 104%;
    @noselect;
  }
  .idea-details {
    & svg#svg-lines {
      margin-top: 19px;
      width: 105%;
    }
    & .stacks h2 {
      font-size: 1.35em;
    }
  }
  .stacks { 
    & .stack div {
      font-size: .7em;
      line-height: 74px;
      height: 75px;
      white-space: nowrap;
    }
    & div.number {
      font-size: 1em;
      line-height: 28px;
      height: 30px;
      width: 30px;
    }
  }
  .ig-approach {
    & .icon-heading-content {
      margin-top: unset;
    }
  }
  .tech-container {
    & div.tech-logo {
      margin-right: 45px;
      margin-bottom: 20px;
      max-width: 30vw;
      & img {
        max-height: 55px;
      }
    }
  }
}
@media (max-width: 768px) {
  ul.tabs li {
    /* width: 50%; */
  }
  .for-mob,
  .for-mobanim-one {
    display: block!important;
    & .navdots-1 {
      padding-top: 19px;
    }
  }
  .notmobile,
  .notmobile.flex {
    display: none!important;
  }
  
  .container.first-stack-group {
    max-width: 270vw!important;
    width: 270vw!important;
    /* margin-left: 0vw; */
    margin-left: 2vw;
    padding-bottom: 0px;
    & .col.stacks {
      padding-left: 10px;
      padding-right: 10px;
    }
    & div.mask.left,
    & div.mask.right {
      position: absolute;
      background: white;
      /* background: green; */
      height: 142px;
      right: 0;
      width: 8vw;
      z-index: 23;
    }
    & div.mask.left {
      left: 0;
      width: 7vw;
      width: 5vw;
    }
  }
  .ig-approach {
    & svg#svg-lines {
      display: none;
      @noselect;
    }
    & div.move-forward,
    & .section-space.move-forward {
      /* background-image: radial-gradient(circle at -196% 115%, #c5e2ee 30%, var(--color-white) 69%, #e7f3ee 115%); */
      background-image: radial-gradient(circle at -146% 195%, #c5e2ee 40%, var(--color-white) 69%, #e7f3ee 115%);
    }
    & .image-left,
    & .image-right {
      padding: 0 15px;
    }
    &.idea-details {
      & svg#svg-lines {
        display: block;
        margin-top: 12.5vw;
        margin-left: -10px;
        width: 110%;
      }
      & .idea-progress-diagram {
        left: 0;
        position: absolute;
        width: 91vw;
        /* & img::after {
          margin-bottom: 65px;
        } */
        & .stacks div.number {
          /* font-size: 1em; */
          line-height: 28px;
          height: 30px;
          width: 30px;
        }
      }
      & .flickity-page-dots {
        margin-top: 3em;
      }
      & .stacks .stats.show p {
        padding-right: 60px;
      }
      & .container.first-stack-group {
        max-width: 360vw!important;
        width: 360vw!important;
        /* margin-left: 0vw;
        padding-bottom: 0px; */
        & img {
          margin-left: -4vw;
          width: 104%;
        }
      }
    }
    & .for-mob.next {
      position: absolute;
      background: white;
      cursor: pointer;
      margin-top: 27px;
      padding: 15px;
      right: 10px;
      width: 90px;
      z-index: 15;
      & .blue {
        color: var(--text-blue);
        font-size: .8em;
        font-weight: 600;
        letter-spacing: 2px;
      }
      & .next-text {
        font-weight: 600;
      }
    }
    & #label-one,
    & #label-two,
    & #label-three,
    & #label-four,
    & .label-one,
    & .label-two,
    & .label-three,
    & .label-four {
      display: none;
    }
    & .row-stacks {
      min-height: 135px;
    }
    & .stacks {
      & .stack div {
        font-size: unset;
      }
      & p, 
      & li {
        font-size: 1rem;
      }
      &.col-12 {
        padding-top: 2em;
        &.label-one-t {
          padding: 0 32px;
          & li {
            margin-top: 0px;
          }
          /* & ul.stats-t {
            
          } */
        }
      }
    }
    & .our-approach a {
      margin-left: 43px;
    }
    & .btn {
      float: left!important;
    }
    & .row-stacks .stacks.col-12 {
      padding-top: 0em;
    }
  }
  .anim-container {
    &.anime-scroller {
      /* margin-left: '0vw'; */
      & .lines-anim {
        & .col.stacks {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      & .slider {
        margin-left: -2.5vw;
        max-width: 360vw!important;
        width: 360vw!important;
        padding: 0 15px;
      }
      & .stacks {
        /* & h2 {
          font-size: 1.5em;
        } */
        &.for-mob .number {
          margin-bottom: 7px;
        }
        & .stack div {
          /* border-top: solid 5px white; */
          border-top-color: #C6E3EF;/* //#E0F0F6; */
          font-size: 1.1em;
          line-height: 29vw;
          height: 29vw;
        }
        & div.number {
          font-size: 1em;
          line-height: 44px;
          height: 45px;
          width: 45px;
        }
      }
    }
  }
  .tech-container {
    margin-bottom: 0;
    & div.tech-logo {
      margin-bottom: 20px;
      max-width: 30vw;
      & img {
        max-height: 50px;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .ig-container .row .col.stacks h2 {
      font-size: 1.75em;
  }
  .tech-container {
    & div.tech-logo {
      margin-right: 55px;
      margin-bottom: 20px;
      max-width: 30vw;
      & img {
        max-height: 47px;
      }
    }
  }
  .ig-approach.idea-details .container.first-stack-group {
    & img {
      margin-left: -5vw;
      min-height: 0;
      /* height: auto!important; */
      width: 103%; 
      /* width: unset;  */
      /* padding-left:0;
      padding-right:0;
      width: 100%!important;
      height: 100% !important; */
    }
  }
}
@media (max-width:465px) {
  .tech-container {
    & div.tech-logo {
      margin-right: 35px;
      margin-bottom: 16px;
      max-width: 35vw;
      & img {
        max-height: 42px;
      }
    }
  }
}