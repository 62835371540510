:root {
  --primary: #000e20;
  --secondary: #002942;
  --black: #000000;
  --dark-teal: #1d4043;
  --hyper-blue: #00AADA;
  --arrow-blue: #1dc9f7;
  --text-blue: #00aada;
  --arrow-turquoise: #00bbe2;
  --greenblue: #1ecba0;
  --greenblue-40: rgb(30 203 160 / 40%);
  --green: #1ecba0;  
  --pink: #fb958b;
  --salmon: #fb958b;
  --copper: #cb8a1e;
  --gold: #ef9f19;
  
  
  --navy-blue:#001a34;
  --grey-light: #ececec;
  --gray-prime: #bdc8d6;
  --color-prime: #000e48;
  --color-white: #ffffff;
  --dark-navy-blue-90: rgba(0, 14, 32, 0.9);
  --brownish-grey: #767676;
  --headline-blue: #7ae3ff;
  --arrow-dk-blue: #222222;
  --elevation-shadow-00-dp: #fafafa;
  --brownish-grey-two: #5a5a5a;
  --brownish-grey-three: #666666;
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-black {
  color: var(--black) !important;
}

.text-grey-light {
  color: var(--grey-light) !important;
}

.text-dark-teal {
  color: var(--dark-teal) !important;
}

.text-hyper-blue {
  color: var(--hyper-blue) !important;
}

.text-arrow-blue {
  color: var(--arrow-blue) !important;
}

.text-text-blue {
  color: var(--text-blue) !important;
}

.text-arrow-turquoise {
  color: var(--arrow-turquoise) !important;
}

.text-greenblue {
  color: var(--greenblue) !important;
}

.text-green {
  color: var(--green) !important;
}

.text-pink {
  color: var(--pink) !important;
}

.text-salmon {
  color: var(--salmon) !important;
}


.text-gray-prime {
  color: var(--gray-prime) !important;
}

.text-color-prime {
  color: var(--color-prime) !important;
}

.text-color-white {
  color: var(--color-white) !important;
}

.text-dark-navy-blue-90 {
  color: var(--dark-navy-blue-90) !important;
}

.text-brownish-grey {
  color: var(--brownish-grey) !important;
}

.text-headline-blue {
  color: var(--headline-blue) !important;
}

.text-arrow-dk-blue {
  color: var(--arrow-dk-blue) !important;
}

.text-elevation-shadow-00-dp {
  color: var(--elevation-shadow-00-dp) !important;
}

.text-brownish-grey-two {
  color: var(--brownish-grey-two) !important;
}

.text-brownish-grey-three {
  color: var(--brownish-grey-three) !important;
}



.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-black {
  background-color: var(--black) !important;
}

.bg-grey-light {
  background-color: var(--grey-light) !important;
}

.bg-dark-teal {
  background-color: var(--dark-teal) !important;
}

.bg-hyper-blue {
  background-color: var(--hyper-blue) !important;
}

.bg-arrow-blue {
  background-color: var(--arrow-blue) !important;
}

.bg-text-blue {
  background-color: var(--text-blue) !important;
}

.bg-arrow-turquoise {
  background-color: var(--arrow-turquoise) !important;
}

.bg-greenblue {
  background-color: var(--greenblue) !important;
}

.bg-green {
  background-color: var(--green) !important;
}

.bg-pink {
  background-color: var(--pink) !important;
}

.bg-salmon {
  background-color: var(--salmon) !important;
}

.blue-to-green {
  /* background-image: radial-gradient(circle at -6% 185%, #c5e2ee 0%, #ffffff 60%, #e7f3ee 116%); */
  /* background-image: radial-gradient(circle at -25% 155%, #c5e2ee 0%, #ffffff 60%, #e7f3ee 96%); */
  background-image: radial-gradient(circle at -30% 67%, #c5e2ee 10%, rgba(255,255,255, 1) 62%, #e7f3ee 89%)
}
.red-blue-radial {
  background-image: radial-gradient(circle at -6% 185%, #eac7c4 0%, #ffffff 60%, #dcf0f8 117%);
}
.darkgreen-radial {
  background-image: radial-gradient(circle at 114% 107%, #004950 0%, #005962 37%, #002942 120%);
  color: white;
}
.darkgreen-radial-center {
  background-image: radial-gradient(circle at center, #004950 36%, #002942 119%);
  color: white;
}
.darkgreen-radial li, 
.darkgreen-radial-center li{
  color: white;
}
.red-teal-radial {
  background-image: radial-gradient(circle at 74% 50%, #061f2f, #865a55 131%);
}
.teal-light-to-dark {
  background-image: radial-gradient(circle at 114% 107%, #002942, #005962 95%, #004950 136%);
}
.teal-light-to-dark h1,.teal-light-to-dark h2, .teal-light-to-dark p,
.red-teal-radial, .red-teal-radial h2 {
  color: white;
}
#footer {
  background: black;
  min-height: 153px;
}
