.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.nomouse {
  pointer-events: none;
}

.x-nav {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.x-nav div.lines {
  position: relative;
  width: 555px;
}
.x-nav div svg {
  position: absolute;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -ms-transition: -ms-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
}
.x-nav div .top,
.x-nav div .bottom,
.x-nav div .right,
.x-nav div .left,
.x-nav div .d-top,
.x-nav div .d-bottom,
.x-nav div .d-right,
.x-nav div .d-left {
  height: 23px;
  position: absolute;
  z-index: 2;
  height: 20px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  cursor: pointer;
  margin: auto;
  padding: 5px;
  width: 20%;
  opacity: 1;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}
.x-nav div.fade {
  cursor: default;
  opacity: 0;
}
.x-nav div.nav-btn {
  cursor: pointer;
  outline: none;
  padding-top: 9px;
}
.x-nav div.selected {
  color: #0AB1E1;
}
.x-nav div .right,
.x-nav div .left {
  width: 15%;
}
.x-nav div .top {
  top: 20%;
  left: 0;
  right: 0;
}
.x-nav div .bottom {
  bottom: 20%;
  left: 0;
  right: 0;
}
.x-nav div .right {
  top: 47.5%;
  right: 10%;
}
.x-nav div .left {
  top: 47.5%;
  left: 10%;
}
.x-nav div.topContent,
.x-nav div.bottomContent,
.x-nav div.rightContent,
.x-nav div.leftContent {
  color: white;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 65%;
  width: 50%;
}
.x-nav div.bottomContent {
  top: initial;
  bottom: 65%;
}
.x-nav div.rightContent,
.x-nav div.leftContent {
  bottom: 45%;
  margin: initial;
  top: initial;
}
.x-nav div.topContent > div,
.x-nav div.bottomContent > div {
  padding: 0 35px;
}
.x-nav div.rightContent > div,
.x-nav div.leftContent > div {
  padding: 0 40px;
}
.x-nav div.rightContent {
  right: 10%;
}
.x-nav div.leftContent {
  left: 50%;
}
.x-nav div h2,
.x-nav div h3,
.x-nav svg text {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, Geneva, Tahoma, sans-serif;
  margin-block-end: 0.2em;
  margin-block-start: 0.2em;
}
.x-nav .content h2,
.x-nav .content h3,
.x-nav p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8em;
}
.x-nav hr {
  border: none;
  height: 1px;
  color: #fff;
  /* old IE */
  background-color: #fff;
  /* Modern Browsers */
  width: 56px;
}
.x-nav {
  position: relative;
  filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.25));
}
.x-nav .sign {
  position: absolute;
  width: 93%;
  transform: translate(0.65%, 5%) scale(1.012);
}
.x-nav div.nav-btn {
  outline: none;
  padding-top: 0;
}
div .d-top,
div .d-bottom,
div .d-right,
div .d-left {
  position: absolute;
  height: 44%;
  width: 44%;
}
div .d-top {
  top: 0;
  left: 25%;
}
div .d-right,
div .d-left {
  top: 25%;
  left: 0px;
}
div .d-right {
  left: 50%;
}
div .d-bottom {
  left: 25%;
  top: 50%;
}
.close-btn {
  cursor: pointer;
}
.label-head {
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 2.05px;
}
.body-text {
  font-size: 0.6em;
  letter-spacing: 1px;
}
div.nav-btn {
  border-radius: 50%;
}
.x-nav div.nav-btn,
.x-nav div.nav-btn:focus,
.x-nav div.nav-btn:selected,
.x-nav div.nav-btn:active {
  border: solid 1px transparent !important;
}
.small-diamond .diahover {
  opacity: 0.001;
}
.small-diamond .diahover:hover {
  opacity: 0.128;
}
.small-diamond .diahover:hover g path {
  fill: white;
}
