/* FONTS*/
$book: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

.weight-normal {
  font-weight: $book;
}
.weight-bold {
  font-weight: $bold;
}

*,
button:focus {
  outline: none;
}

a,
a:hover,
a.btn-link,
.btn-link:hover {
  color: var(--arrow-turquoise);
  cursor: pointer;
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
p {
  color: var(--primary);
  font-family: freight-sans-pro, open-sans, sans-serif;
  font-style: normal;
  font-weight: $book;
  letter-spacing: 1px;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}
p {
  font-weight: $book;
  margin-block-end: 1em;
}

b,
p strong,
.bold {
  font-weight: $semibold;
}

p,
li {
  font-family: freight-sans-pro, open-sans, sans-serif;
  font-size: 1.2em;
  line-height: 1.7em;
}
li,
ul li ul,
ol,
ol li ol {
  font-size: 1.25rem;
  font-weight: $book;
  color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
p,
span {
  &.green {
    color: var(--greenblue);
  }
}
h1, h2, .h2  {
  font-size: 3em;
  font-weight: $medium;
}

h3, .h3 {
  font-size: 1.25em;
  font-weight: $semibold;
  text-transform: uppercase;
}

.h1 {
  font-size: 4em;
  font-weight: $semibold;
}

.fw-4 {
  font-weight: $book;
}
.fw-5 {
  font-weight: $medium;
}

h2.decorated {
  font-size: 1.5em;
  font-weight: $medium;
  letter-spacing: 3px;
  margin-left: -6px;
  margin-block-start: 0.3em;
  margin-block-end: 0em;
  text-transform: uppercase;
  width: fit-content;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  z-index: 2;
  clear: both;
}
/* h2.decorated + h2.decorated { */
h2.decorated:last-of-type {
  margin-block-start: 0em;
  margin-block-end: 0.7em;
}

h2.decorated::before {
  content: '\00a0 ';
  letter-spacing: 0;
}
h2.decorated::after {
  content: '';
  background: #1ecba0;
  display: block;
  height: 0.46em;
  margin-top: -0.46em;
  opacity: 0.4;
  padding: 0 1px;
  position: relative;
  width: 100%;
  z-index: -1;
}
.icon-heading h2.decorated {
  margin-block-start: 0.3em;  /* !important; */
  margin-block-end: 0;  /* !important; */
  margin-right: 2px; /*fix overlapping issue*/
}
h3.brownish-heading {
  font-size: 1em;
  line-height: 1.25;
  letter-spacing: 1px;
  color: var(--brownish-grey-three) !important;
  font-weight: $medium;
}
h3.brownish-grey-heading {
  font-size: 1em;
  line-height: 1.25;
  letter-spacing: 1px;
  color: var(--brownish-grey) !important;
  font-weight: $medium;
}
/* .blue-to-green h2.decorated {font-size: 1.5em; font-weight: 500; letter-spacing: 3px; margin-left: -6px; margin-block-start: 0.3em; margin-block-end: 0em; text-transform: uppercase; width: fit-content;}
.blue-to-green h2.decorated+h2.decorated {margin-block-start: 0em; margin-block-end: 0.7em;}
.blue-to-green h2.decorated::before {content: "\00a0 "; letter-spacing: 0;}
.blue-to-green h2.decorated::after {content: ""; background: #1ecba0; display: block; height: 0.46em; margin-top: -0.46em; opacity: 0.4; padding: 0 1px; position: relative; width: 100%; z-index: 0;}
 */ /*.blue-to-green .icon-heading h2.decorated {
  margin-block-start: 0.3em !important; 
  margin-block-end: 0 !important;
}*/

ul.square-bullets {
  display: grid;
  list-style: none !important;
  margin: 11px 0;
  padding-left: 0;
}
ul.square-bullets li {
  display: inline-flex;
}
ul.square-bullets li::before {
  content: '\25A0';
  color: #1ecba0;
  display: inline-block;
  font-size: 0.8em;
  padding-bottom: 11px;
  width: 1.5em;
  margin-left: -3px;
}
ul.square-bullets li::before {
  content: '\25A0';
  color: #1ecba0;
  display: inline-block;
  font-size: 0.8em;
  padding-bottom: 11px;
  width: 1.5em;
  margin-left: -3px;
}
ul.square-bullets li ul li {
  font-size: 1em;
}
ul.square-bullets.medium-font li {
  font-size: 1.2em;
  font-weight: $medium;
}
.card-body /* //flipcard override for bullets */
ul.square-bullets li {
  font-size: 1.1em;
}

ul.diomond-bullets {
  display: grid;
  list-style: none !important;
  margin: 11px 0;
  padding-left: 0;
}

ul.diomond-bullets li {
  display: inline-flex;
}

ul.diomond-bullets li::before {
  content: '\25A0';
  color: var(--arrow-blue);
  display: inline-block;
  font-size: 0.8em;
  padding-bottom: 11px;
  width: 1.5em;
  margin-left: -3px;
  transform: rotate(45deg);
}

.bull-diomond::before {
  content: '\25A0';
  color: var(--arrow-blue);
  display: inline-block;
  font-size: 0.8em;
  padding-bottom: 11px;
  width: 1.5em;
  margin-left: -3px;
  transform: rotate(45deg);
}

hr {
  border: none;
  border-top: 1px solid #d1d1d1;
}
.hr {
  border: none;
  border-top: 2px solid #d1d1d1;
}
.dark-background h1,
.dark-background h2,
.dark-background h3,
.dark-background h4,
.dark-background h5,
.dark-background p,
.dark-background ul li {
  color: var(--color-white);
}

.blockquote-primary p {
  font-size: 1.5em;
  line-height: 1.33em;
  letter-spacing: 0.5px;
  font-weight: $semibold;
}

.blockquote-primary .blockquote-footer {
  font-weight: $semibold;
}

.blockquote-primary .blockquote-footer::before {
  display: none;
}

.btn-outline-light {
  color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}
.btn-outline-light:hover {
  color: #212529 !important;
}
