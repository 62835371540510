/*@import url("https://use.typekit.net/mcy4uzs.css");*/ /*need to replace with a Revel Adobe project*/

/* Bootstrap Media Query */
/* // Extra Large devices (Projector, less than 1600px) */
@media (min-width: 1599.98px) {
    .card-one .card-img-box-half {height: 308px;}
  
}
/* // Extra Large widths */
@media (min-width: 1450px) {
    .section-home.section-slide.one h1 {
        font-size: 5em;
    }
    .section-home.section-slide.one p {
        font-size: 1.5em;
    }
    .ig-carousel-group3 .card-one .card-text {
        font-size: 1.25em;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1300px;
    }
    .header-green {
        height: 600px;
    }
    .header-green.small, .header-green-new,
    .header-green.header-second-short {
        height: 460px;
    }
    .header-green.header-green-new h1, 
    .header-green-new h1,
    .header-green.header-second-short h1 {
        max-width: 1300px;
    }
    .header-green h1,
    .header-green-new h1,
    .header-green.header-second-short h1 {
        font-size: 5em;
        line-height: 1.2em;
        margin-block-start: 0.2em;
        max-width: 1020px;
    }
    .header-green.small h1 {
        font-size: 5.9em;
    }
    .ig-container h1 {
        font-size: 3.5em;
    }
    h3.crumbs {
        font-size: 1.2em;
        margin-top: 76px;
    }
    h2 {
        font-size: 4.25em;
        font-size: 3.25em;
    }
    footer h2 {
        font-size: 2.25em;
    }
    p, li,
    .icon-heading-content h4 {
        font-size: 1.3em;
        line-height: 1.7em;
    }
    ul.square-bullets li ul li {
        font-size: 1.3em;
    }
    .ig-carousel .gallery .continue-block {
        width: 478px;
        height: 220px;
    }
    
    .ig-carousel .gallery-cell img{
        height: 627px;
    }

    .ta-item-text  {
        min-height: 353px!important;
    }
    .ta-item-text p {
        font-size: 1.3em;
    }
    /*
    .ta-item-text a {
         font-size: 1.3em !important; 
    } */
    .tilt-playing-image img {
        margin-left: -30%;
        margin-top: -35%;
    }

    .work-approach {
        &:not(.partnership) p {
            max-width: 725px;
        }
    }
    .start-with-user-data .section-space.diamond {
        padding: 125px 0 0 175px;
    }
    .start-with-user-data .section-space.diamond .img-diamond-frame {
        width: 65%;
    }
} 
/* // Extra Large devices (TV, less than 1400px)1399.98px */
@media (max-width: 1449px) { 
    .card-one .card-img-box-half {
        height:215px;
    }
    #react-app {
        transform: scale(.95);
        float: none;
    }
    /* .tabbed-accordian .tabbed-content .ta-tabs li a {
        font-size: 1.5em;
        display: block;
    } */
    .ta-item-text p {
        font-size: 1.1em;
    }
    
    .ig-carousel .gallery-cell img{
        height: 527px;
    }
}
@media (max-width: 1200px) {
    h2 {
        font-size: 2.75em;
    }
    .start-with-user-data .section-space.diamond {
        padding: 125px 0 0 75px;
    }
    .ta-item-text p {
        font-size: 1em;
    }
}
@media (max-height: 790px) {
    .card-one .card-img-box-half {
        height:250px;
    }
    /* .on-home.partners-logos .card-one .card-img-box-half {
        height: unset;
    } */
    .on-home.partners-logos .card-one .card-title {
        font-size: 1em;
    }
    /* .ta-item-text p {
        font-size: 1em;
    } */
}
/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    #react-app {
        transform: scale(.85);
        float: none;
    }
    /*home*/
    .tabbed-accordian .ta-item.active .ta-item-text a {
        display: block;
    }
    .tabbed-accordian .ta-item.active .ta-item-text a.btn {
        display: none;
    }
    /* .transformation img {
        float: right; 
        margin: 0 0 0 15px; 
    } */
    .overflow-sm-hidden {
    overflow: inherit;
    }

    .card-image-short .card-img-box-short{ height: 140px; }
    .card-one .card-img-box-half {height: 210px;}

    .leadership-slider .tab-content .leadershipImg{height: 200px;}

    .move-forward .service-img .rectangle1{top: -95px; right: 28px;}
    .move-forward .service-img .rectangle2{top: -30px; right: -40px;}
    .move-forward {
        background-image: radial-gradient(circle at -6% 115%, #c5e2ee 0%, var(--color-white) 69%, #e7f3ee 115%);
        /* background-image: radial-gradient(circle at -6% 195%, #c5e2ee 20%, var(--color-white) 69%, #e7f3ee 15%); */
    }

  .start-with-user-data .rectangle1{top: 60px; left: -25px;}
  .start-with-user-data .rectangle2{top: 315px; left: 40px;}
  .start-with-user-data .rectangle3{top: 394px; left: -25px;}

  .tilt-playing-image{
    padding-top: 50px; position: relative;
  }
  .tilt-playing-image img{padding-left: 20px;}
  .tilt-playing-image ul li{font-size: 1em; padding-top: 50px;}
  .tilt-playing-image ul li:first-child{top: 10%;}
  .tilt-playing-image ul li:nth-child(2){top: 31%;}
  .tilt-playing-image ul li:last-child{top: 52%;}

    h2.decorated {
        font-size: 1.3em;
    }
    .icon-heading h2.decorated {
        font-size: 1.2em;
    }
    .ig-carousel .gallery-cell img{
        height: 427px;
    }
    .work-approach p {
        max-width: 455px;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    #react-app {
        transform: scale(.8);
        margin-left: 5vw;
    }
    #top-nav .et_pb_image_0_tb_header {
        margin-left: 0;
    }

    #top-list-menu {
        display: none !important;
    }
    #header-main .navbar-nav .nav-item {
        margin-left: 18px !important;
    }
    .no-tablet {
        display: none;
    }
    /*home*/
    .section-home.section-slide.one h1 {
        font-size: 3.2em;
        line-height: 1.1em;
    }
    .transformation {
        text-align: center;
    }
    .transformation img {
        margin: 2em auto;
        float: none; 
        margin: 2em auto; 
    }

    .header-green {
        height: 400px;
    }

    .header-green h1 {
        font-size: 3.5em;
    }

    .header-green.middle h1 {
        font-size: 2.75em;
    }
    .header-second-short {
        height: 320px;
    }
    .header-green h1,
    .header-green-new h1,
    .header-green.header-second-short h1 {
        /* max-width: unset; */
        max-width: 80%;
    }
    .accordian-primary .card-header .btn-block{ font-size: 1.2em; }
    .megamenu-heading { font-size: 2.8em;}

    .crumbs {
        margin-top: 56px;
    }

    .tabbed-accordian .ta-item.active .ta-item-text {
        width: 65%;
        left: -15%;
    }
    
    .checkout-career .background-imgfit,
    .work-approach .background-imgfit,
    .approach-about .background-imgfit {
        width: unset;
    }
    
    .service-img, .Leadership-img {
        margin-top: -87px;
        width: 100%;
    }
    .Leadership-img {
        margin-top: 7px;
    }
    .content-display1 .image-box{ width: 90%;}
    .card-image-short .card-img-box-short{ height: 102px; }
    .card-one .card-img-box-half {height: 322px;}
    
    .ig-home .owl-container {
        position: unset;
        right: unset;
        margin-left: unset;
    }
    .ig-home .ig-carousel-group3 .owl-stage-outer {
        padding-bottom: 2.5rem;
        margin-top: 25px;
    }
    .ig-home .owl-container .owl-nav {
        margin-left: auto;
    }
    .ig-carousel .gallery .continue-block {
        margin: -153px 248px 25px 44px
    }
    .ig-carousel-group2-title {
        margin-left: 5%;
        margin-right: 5%;
    }
    .ig-carousel .gallery-cell img{height:317px;}
    .fluid-content-left { margin-left: 12%;}
    .ig-carousel-group3{margin-left:inherit; top: 30px; position: relative;}
    /* .tabbed-accordian .tabbed-content .ta-item .ta-item-content{ padding: 0px; padding-top: 40px;}
    .tabbed-accordian .ta-item.active .ta-item-text{ position: relative; margin: 0px; margin-top:10%; left:inherit; bottom: inherit; z-index: 1; padding: 60px; padding-right:15%; width: 85%;}
    .tabbed-accordian .ta-item.active .image-box{ position: absolute; width: 30%; right: 0px; top: 10%; z-index: 90;} */
    .leadership-overview .leadership-heading .rectngl {
      display: none;
    }

    .bigImg-cards .bigImg-image{height: 180px;}

    .blog-cards .blog-image{height: 180px;}

    .leadership-slider .tab-content .leadershipImg{height: 150px;}

    .contribution .contribution-sectn p{line-height: 1;}
    .contribution .contribution-sectn img{width: 40px;}
    .contribution .contribution-sectn p{padding-left: 60px;}
    .contribution .contribution-sectn ul{padding-left: 60px;}
    .contribution .contribution-sectn ul li{font-size: 1em; line-height: 1;}

    .imgDropdown .imgDropdown-heading a img{padding-right: 5px; width: 20%;}
    .imgDropdown .imgDropdown-heading a{font-size: 12px;}
    .imgDropdown .imgDropdown-nav .imgDropdown-item a{font-size: 14px;}

    .verify-request{padding-left: 120px;}
    .verify-request p:first-child{width: 100%;}

    .services-partnerDetail img{width: 75%;}
    .services-partnerDetail h2{width: 100%;}
    .services-partner .rectngl.rec4 {top: 7px;}
    .services-partnerImg .rectngl.rec3 {display: none;}

    /* .icon-heading {
        padding-left: 64px;
    } */

    .move-forward .service-img .rectangle1{top: -68px; right: 23px;}
    .move-forward .service-img .rectangle2{top: -22px; right: -25px;}

    .approach-half-slide .owl-nav{position: relative;}
    .approach-half-slide{padding-bottom: 3rem !important;}

    .start-with-user-data .rectangle1{top: 60px; left: 5px;}
    .start-with-user-data .rectangle2{top: 260px; left: 40px;}
    .start-with-user-data .rectangle3{top: 330px; left: 5px;}

    .tilt-playing-image img{
        margin-left: -55%;
        margin-top: 5%;
        width: 190%;
    }
    .tilt-playing-image ul li{text-align: center; left:37%;}

    .floating-form button{margin-top: 30px;}

    .tile-tabs .nav.nav-tabs .nav-item .nav-link{padding-left: 10px; margin-left: 10px;}
    
    .big-rectangleDiamond{ display: none;}
}
@media all and (min-width: 800px) {
    .tabbed-accordian .ta-item.active .ta-item-content {
        padding-top: 0;
    }
    .tabbed-accordian .tabs-side .ta-tabs li {
        margin-bottom: 2px;
    }
    .tabbed-accordian .tabbed-content .ta-tabs {
        display: block;
    }
    .tabbed-accordian .tabbed-content .ta-tabs li {
        display: inline-block;
    }
    .tabbed-accordian .tabbed-content .ta-tabs li a {
        display: block;
    }
    .tabbed-accordian .tabbed-content .ta-item {
        min-height: 0;
        border-width:0px;
    }
    .tabbed-accordian .tabbed-content .ta-item::before, 
    .tabbed-accordian .tabbed-content .ta-item::after {
        display: none;
    }
    .tabbed-accordian .tabbed-content.tabs-side .ta-tabs li {
        display: block;
    }
}
/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
    h2 {
        font-size: 2.5em;
    }
    h4 {
        font-size: 1.5em;
    }
    .section-space {
        padding-bottom: 40px;
        padding-top: 40px;
    }
    .navbar-dark .navbar-brand img {
        width: 105px;
    }
    #header-main {
        padding-top: 20px;
    }
    #header-main .navbar-toggler-icon {
        font-size: 1.25rem;
    }

    .header-green,
    .header-green.small, 
    .header-green-new {
        /* height: 346px; */
        height: unset;
        padding-bottom: 40px;
    }
    .header-green h1 {
        font-size: 3.1em;
    }

    .main-menu-toggler {
        display: none;
    }
    #header-main .navbar-nav .nav-item {
        margin-left: 0px !important;
    }
    footer .social-connect {
        margin-top: unset;
    }
    
    .section-home.section-slide.one h1 {
        font-size: 4.5em;
    }
    .container {
        width: 100%;
        max-width: 100%;
        padding-right: 30px;
        padding-left: 30px;
    }
    .ig-container .row .col h1 {
        font-size: 2.6em;
        margin-top: .8em;
    }
    /*from LESS template*/
    .section-hero-img,
    .square-hero-img {
        position: relative;
        float: none;
        margin-top: 55px;
        margin-top: 0;
        /* padding-top: 30px; */
        width: unset;
    }
    .idea-details .square-hero-img {
        padding-top: 30px;
    }
    .rectangle {
        &.small, 
        &.image {
            display: none;
        }
    }
    /*Approach*/
    .tilt-playing-image {
        margin: -1% 0 -20%;
        padding-top: 0px;
        position: relative;
    }
    .tilt-playing-image img {
        padding-left: unset;
        position: unset;
        padding-left: unset;
        position: unset;
        width: unset;
        left: unset;
        margin-left: unset;
        margin-top: unset;
    }
    .img-diamond-frame {
        display: none;
    }
    .accordian-primary .card-header .btn-block{ font-size: 1.2em; }
    .megamenu-heading { font-size: 2.8em;}
    .crumbs {
        margin-top: 15px;
    }

    .custom-tabs ul.et_pb_tabs_controls.clearfix {
        float: left;
        width: 100%;
        display: inherit;
    }

    .custom-tabs .et_pb_all_tabs {
        display: flex;
        width: 100%;
    }
    
    .custom-tabs .et_pb_tab.et_pb_tab.clearfix.et-pb-active-slide {
        border-left: 0px solid #d9d9d9;
    }

    .custom-tabs .et_pb_tab_content {
        padding: 35px;
    }
    .section-home.section-slide{min-height: inherit;}
    .ig-slide,
    .ig-blade {
        min-height: 256px;
    }

    .ig-row {
        margin: 0 auto;
        padding: 20px 0;
        width: 90%;
    }

    .overflow-md-hidden {
        overflow: hidden;
    }

    .display-md-none {
        display: none;
    }

    #header-main .navbar-nav .nav-item {
        margin-left: 1px !important;
    }
    #header-main .navbar-nav .nav-link {
        padding-right: .3rem;
        padding-left: .3rem;
        font-size:0.75em;
        letter-spacing: 1px;
    }

    .floating-form {
        margin-top: 35px;
        min-height: 444px;
        position: relative;
        right: initial;
        top: 0px;
    }
    .contact-form {
        min-height: 444px;
    }
    .verify-request{padding-left: 0;}
    .contact-form{padding-bottom: 0;}

    .service-img {
        margin-top: 45px;
        margin-bottom: 45px;
        width: 100%;
    }

    .service-img .rectangle {
        width: 20%;
    }

    .service-img .rectangle1 {
        left: -10%;
        bottom: 17%;
        z-index: 9;
    }

    .service-img .rectangle2 {
        left: 66%;
        bottom: -10%;
        z-index: -1;
    }

    .service-img .rectangle3 {
        right: -18%;
        top: 35%;
        z-index: -1;
    }

    .service-img .rectangle4 {
        right: 11%;
        top: -7%;
        z-index: 9;
    }

    .service-img .rectangle5 {
        right: -38%;
        bottom: 6%;
        z-index: 9;
    }
    .start-with-user-data .section-space.diamond {
        padding: 0;
    }
    .free-rectangle .rectangle,
    .diamond .rectangle {
        display: none;
    }
    .content-display1 .image-box{ width: 100%;}
    .card-image-short .card-img-box-short{ height: 315px; }
    .grid-box-title p{  max-width:100%; }
    .card-flip-front .card{overflow: hidden;}
    .card-one .card-img-box-half {height: 300px;}
    .card-two .card-img-box-half{height: 254px;}

    .blockquote-primary p {
        font-size: 1.2em;
    }

    .icon-heading-content {
        /* padding-left: 78px; */
        padding-top: 18px;
    }

    .content-display2 {
        padding-left: 78px;
        padding-top: 18px;
    }
    .ig-carousel-group2-title {
      margin-left: 5%;
      margin-right: 0%;
    }
    .ig-carousel .gallery-cell img{height:317px;}
    .ig-carousel .flickity-page-dots {
        position: relative;
        width: 140px;
        bottom: inherit;
        right: auto;
        text-align: right;
        margin: auto;
        top: 57px;
    }
    .fluid-content-left { margin-left:2%;}
    .get-there-together {
        width: 100%;
    }
    .get-there-together .rectangle1 {
        top: -7%;
        left: -21%;
        right: inherit;
    }
    .get-there-together .rectangle2 {
        top: 20%;
        right: -17%;
    }
    .get-there-together .rectangle3 {
        top: 82%;
        right: -11%;
        z-index: 1;
    }
    .tools-box {
        position: relative;
        width: 100%;
        margin-top: 22px;
    }
    /* Tabbed */
    .tabbed1-vertical {
        min-height: inherit;
    }
    .tabbed1-vertical .nav-pills {
        display: flex;
        flex-wrap: wrap;
        flex-direction: inherit !important;
        align-items: stretch;
        justify-content: center;
        border-bottom: 1px solid rgba(0, 0, 0, .125);
        margin-right: inherit;
        /* margin-left: -30px;
    margin-right: -30px; */
    }
    .tabbed1-vertical .nav-pills .nav-link {
        padding: 2rem 1rem;
        display: inline-block;
        border-width: 0px;
        flex: 33.33%;
        font-size: 0.9rem;
    }
    .tabbed1-vertical .nav-pills .nav-link span {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%)
    }
    .tabbed1-vertical .nav-pills .nav-link.active,
    .tabbed1-vertical .nav-pills .show>.nav-link {
        background: var(--arrow-blue) !important;
        color: var(--color-white) !important;
    }
    .tabbed1-vertical .nav-pills .nav-link.active::before,
    .tabbed1-vertical .nav-pills .show>.nav-link::before {
        display: none;
    }
    .tabbed1-vertical .tab-content{padding-top: 1.2rem;}

    .approach-about p, .partner-about p {
        max-width: unset;
        width: 100%;
    }
    .services-partnerImg .partner-image{
        width: 100%;
        margin-left: 0;
        margin-top: 28px;
    }
    /* =================*=============== */
    .tabbed-accordian .ta-item.active .ta-item-content{ display: flex; align-items: stretch;}
    .tabbed-accordian .image-box {flex-grow: 1;}
    .tabbed-accordian .image-box .rectangle2{
        top: -1%;
        left: -176%;
        z-index: 0;
    }
    .tabbed-accordian .ta-item.active .ta-item-text{
        flex-grow: 2;
        margin: unset;
        bottom:inherit;
        left:inherit; 
        position: inherit;
        width: inherit;
        background: transparent;
        box-shadow: none;
        padding-left: 0px;
        
    }
    .tabbed-accordian .ta-item.active .ta-item-text h3{ display: none;}
    .tabbed-accordian .ta-item.active .ta-item-text p {
    /* .tabbed-accordian .ta-item.active .ta-item-text a */
        color: var(--color-white) !important;
    }
    .tabbed-accordian .ta-item.active .ta-item-text a i{color: var(--arrow-turquoise) !important;}
    /* ===============*================= */
    /* CLose Tabbed */

    .custom-accordion button:active, /**/
    .custom-accordion button:focus {
        outline:0;
        box-shadow: unset;
    }
    /* .custom-accordion .card:last-child {
        border: none
    } */
    .custom-accordion .card-body p, .custom-accordion .card-body ul li {
        font-size: 1em;
    }

    .icon-heading h2.decorated,
    h2.decorated {
        font-size: 1.5em;
    }
    .situation-div h2.decorated, 
    .action-div h2.decorated,
    .casestudy-main .imageCaption {
        font-size: 1.25em;
        justify-content: flex-start;
        margin-left: 0;
        margin-bottom: 20px;
    }
    .casestudy-main hr {
        margin: unset;
    }
    .theMiddlebg {
        background-position: top 0px right 100%;
    }

    .footer-logo {
        position: inherit;
        bottom: inherit
    }

    .footer-content-end {
        -ms-flex-pack: inherit !important;
        justify-content: inherit !important;
    }

    .footer-connect-btn {
        float: left;
    }

    .footer-navbar .nav-item {
        margin-left: 0px;
        width: 100%;
    }

    .footer-navbar2 .nav-item {
        margin-left: 0px;
        margin-right: 20px;
    }

    .footer-navbar .nav-link {
        padding: 0px;
        padding-bottom: 4px;
    }

    .social-navbar .nav-item {
        margin-left: 2px;
    }

    .copyright {
        text-align: left;
        font-size: .7em;
    }

    .bigImg-cards .bigImg-image{height: 260px;}

    .blog-cards .blog-image{height: 260px;}
    
    .partners .partners-image{padding-top: 40px;}

    .imgDropdown .imgDropdown-heading a img {
        width: auto;
    }

    /* .theMiddlebg{background-position: top 700px right 100%;} */

    .services-partnerImg .rectngl.rec1 {display: none;}

    .start-with-user-data .rectangle{width: 25%;}
    
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    h2 {
        font-size: 2.25em;
    }
    h4 {
        font-size: 1.25em;
    }
    .section-space {
        padding-bottom: 20px;
        padding-top: 20px;
    }
    /* #react-app {
        width: 100vw;
    } */
    #header-main {
        padding-top: 20px;
    }

    #header-main .navbar-nav .nav-item {
        margin-left: 0px !important;
    }
    p, li,
    .icon-heading-content h4 {
        font-size: 1.1em;
        line-height: 1.7em;
    }
    .btn {
        border-radius: 5px;
        border-width: 2px;
        font-size: .8em;
        font-weight: 600;
        letter-spacing: 2px;
        line-height: 1em;
        padding: 1.3em 1.35em;
    }
    .btn .narrow {
        display: inline-block;
    }
    .btn .wide {
        display: none;
    }
    .ig-container .row .col h1,
    .approach-about h1,
    .start-with-user-heading h1 {
        margin-block-end: 0.4em;
        margin-block-start: .4em;
        font-size: 2.5em;
    }
    .ig-container .row .col h2,
    .approach-about h2,
    .start-with-user-heading h2{
        margin-block-end: 0.4em;
        margin-block-start: .4em;
        font-size: 2.25em;
    }
    .balance-action h2 {
        font-size: 2.5em;
        padding-top: 0px;
    }
    .balance-action p {
        font-size: 1em;
    }
    .ig-carousel-group2-title a {
        white-space: nowrap;
    }
    /*Home*/
    /* .ig-home h1 {
        font-size: 2.5em;
    } */
    .transformation img {
        width: 90%;
    }
    footer .btn {
        margin-bottom: 25px;
    }
    footer .social-navbar .nav-link {
        width: 17px;
        height: 17px;
        line-height: 12px;
        padding: 3px;
        /* text-align: center; */
        font-size: 11px;
        border-radius: 50%;
    }
    footer .social-navbar .nav-item {
        margin-left: 10px;
    }
    .section-home.section-slide.one h1,
    .ig-home h1 {
        font-size: 2.65em;
        line-height: 1.1em;
        margin-bottom: .3em;
        font-weight: 500;
    }
    .fp-tableCell {
        margin-top: 40px;

    }
    /*end Home*/
    .card.card-one .card-img-box-half {
        height: 188px;
    }

    .header-green {
        /* height: 290px; */
        padding-bottom: 40px;

        & h1 {
            font-size: 2.75em;
        }
        & h1,
        & h3 {
            width: 85%;
        }
        & h3.crumbs {
            /* font-size: 1em; */
            font-size: .8rem;
            letter-spacing: 2px;
            margin-bottom: 15px;
            width: unset%;
        }
    }
    .header-green, 
    .header-green.small {
        padding-top: 93px;
    }

    .megamenu .closebtn {
        font-size: 34px;
        top: 41px;
        right: 28px;
    }
    .megamenu-content .links {
        margin: 3vh auto;
    }
    .megamenu-content .links .col + .col {
        margin-top: 2em;
    }

    .main-menu-toggler {
        display: none;
    }

    .display-sm-none {
        display: none;
    }

    .overflow-sm-hidden {
        overflow: hidden;
    }

    .move-forward {background-image: radial-gradient(circle at -6% 85%,
        #c5e2ee 20%, 
        var(--color-white) 69%, 
        #e7f3ee 115%)
    }
    .move-forward .service-img {
        display: none; /*todo: temporary*/
        margin-top: 25px;
        width: 100%;
    }

    .collage1{margin: 0 12% 35% 21%;}
    .collage1-left {
        width: 62%;
        padding-top: 79%;
        top: 10%;
    }
    .collage1-right {
        position: absolute;
        width: 59%;
        padding-top: 59%;
        top: 65%;
        right: -28%;
        z-index: 2;
    }
    .collage1-center .collage-content .collage-label{ padding-left: 2rem;}
    .collage1 .diamond-pattern3 {right: 16%; }
    .collage1 .diamond-pattern1 { left: -90%;}
    .collage-content .collage-label p, .collage-content .collage-label strong {
        font-size: 0.7rem !important;
    }

    .card-image-short .card-img-box-short{ height:200px; }
    .card-one .card-img-box-half {height: 320px;}
    .card-two .card-img-box-half{height: 234px;}
    .container {
        width: 100% !important;
    }
    .fluid-content-left {
        margin-left: 5%;
    }
    .tabbed-accordian .ta-item.active .ta-item-content{ display: inherit; align-items: inherit; }
    .tabbed-accordian .image-box {
        display: none; /*todo: temp for mvp*/
        flex-grow: inherit;
    }
    .tabbed-accordian .ta-item.active .ta-item-text{ 
        flex-grow:inherit; 
        padding: 0 0 15px;
        padding-top: 0px; padding-right: 0px;
        min-height: unset;
    }
    .tabbed-accordian .ta-item.active .ta-item-text a {
        position: unset;
    }

    .blockquote-primary p {
        font-size: 1.07em;
    }
    
    .ig-carousel {
        min-height: 645px !important;
    }

    .ig-carousel .gallery-title {
      margin-left: 4%;
    }

    .ig-carousel .gallery-cell {
        margin-right: 60px;
        width: 98%;
    }

    .ig-carousel .gallery .continue-block {
        width: 98%;
        min-width: 346px;
        height: 216px;
        bottom: -214px;
        margin: -153px 248px 25px 14px;
        padding: 21px 48px 24px 24px;
    }

    .ig-carousel .flickity-page-dots {
        position: relative;
        /* width: 150px; */
        bottom: inherit;
        right: auto;
        text-align: right;
        margin: auto;
        top: 212px;
    }
    .ig-carousel .gallery-cell img{height:196px;}
    .footer-logo {
        position: inherit;
        bottom: inherit;
        width: 111px;
    }

    footer h2 {
        font-size: 1.5em;
        margin-bottom: 22px;
        width: 70%;
    }
    .footer-content-end {
        -ms-flex-pack: inherit !important;
        justify-content: inherit !important;
    }

    .footer-connect-btn {
        float: left;
    }

    .footer-navbar .nav-item {
        margin-left: 0px;
        width: 100%;
    }

    .footer-navbar2 .nav-item {
        margin-left: 0px;
        margin-right: 20px;
    }

    .footer-navbar .nav-link {
        padding: 0px;
        padding-bottom: 4px;
    }

    .social-navbar .nav-item {
        margin-left: 2px;
    }

    /* .copyright {
        text-align: left;
    } */

    .singleSlider .singleSliderData{width: 100%;}
    .singleSlider.owl-theme .owl-dots{position: initial;}

    .counter ul{flex-direction: column;}
    .counter ul li.borderSeperator { height: 62px; }
    .counter ul li.borderSeperator img { transform: rotate(90deg); margin-top: -65px; }

    .caseStudy-main .imageCaption{justify-content: initial;}
    .caseStudy-main .imageCaption p{width: 100%;}

    .bigImg-cards .bigImg-image{height: 200px;}

    .blog-cards .blog-image{height: 200px;}

    .leadership-slider .tab-content .leadershipImg{height: 250px;}

    .imgDropdown .imgDropdown-heading{padding: 15px 0; text-align:left;}
    .imgDropdown .imgDropdown-heading a img{width: 15%;}

    .services-partnerImg .rectngl.rec3 {display: block;}

    .move-forward .service-img .rectangle1{top: -65px; right: 29px;}
    .move-forward .service-img .rectangle2{top: -22px; right: -15px;}

    .start-with-user-data .rectangle1{top: 50px; left: 5px;}
    .start-with-user-data .rectangle2{top: 300px; left: auto; right:35px;}
    .start-with-user-data .rectangle3{display: none;}
    
    .tilt-playing-image {
        margin: -1% -75px -20%;
        padding-top: 0px;
        position: relative;
        width: 310%;
    }
    .tilt-playing-image ul li:first-child{top: 14%;}
   .tilt-playing-image ul li:nth-child(2){top: 40%;}
   .tilt-playing-image ul li:last-child{top: 68%;}

   .logo-rows .logo-item{ max-width: 50%; margin-bottom: 12%;}
}

@media (max-width:405px) {
    h2 {
        font-size: 2.1em;
        max-width: 94%;
    }
    #react-app {
        transform: scale(.7);
        margin-top: -35px;
    }
    .section-home {
        &.section-slide {
            padding: 17% 0 8%
        }
        &.one p {
            padding-right: 0;
        }
    }
    .section-space {
        padding-bottom: 0px;
        padding-top: 0px;
    }
    .card-content-only .card-text, .card-image-short 
    .card-text {
        font-size: 1.05em;
        line-height: 1.5;
        letter-spacing: 0px;
    }
    h2.decorated {
        font-size: 1.25em;
    }
    .ig-carousel .gallery-cell img {
        height:175px;
    }
    .no-br-xs-none{white-space: unset;}
}

@media (max-width:376px) { /* 360.98px */
    #header-main {
        padding-top: 20px;
    }
    .diamond-container {
        max-height: 40vh;
        & #react-app {
            transform: scale(.60);
            margin-top: 65px;
            margin-left: 4vw;
        }
    }
    .section-home {
        & p, & li {
            font-size: 1em;
            line-height: 1.6em;
        }
    }
    
    .ig-carousel {
        min-height: 620px !important;
    }

    .ig-carousel .gallery-cell {
        margin-right: 60px;
        width: 98%;
    }

    .ig-carousel .gallery .continue-block {
        width: 100%;
        min-width: 287px;
        height: 226px;
        bottom: -226px;
        margin: -153px 248px 25px 12px;
        padding: 15px 44px 15px 15px;
    }
    
    .ig-carousel .gallery .continue-block h2 {
        font-size: 1.2em;
    }
    .ig-carousel .gallery-cell img{height:166px;}
    .card-image-short .card-img-box-short{ height:125px; }
    .card-one .card-img-box-half {height: 315px;}
    .card-two .card-img-box-half{height: 254px;}
    .footer-logo {
        position: inherit;
        bottom: inherit
    }

    .footer-content-end {
        -ms-flex-pack: inherit !important;
        justify-content: inherit !important;
    }

    .footer-connect-btn {
        float: left;
    }

    .footer-navbar .nav-item {
        margin-left: 0px;
        width: 100%;
    }

    .footer-navbar2 .nav-item {
        margin-left: 0px;
        margin-right: 20px;
    }

    .footer-navbar .nav-link {
        padding: 0px;
        padding-bottom: 4px;
    }

    .social-navbar .nav-item {
        margin-left: 2px;
    }

    .copyright {
        text-align: left;
    }

    .leadership-slider .tab-content .leadershipImg{height: 170px;}

    .tilt-playing-image ul li{left:33%}              
    .tilt-playing-image ul li:first-child{top: 11%;} 
    .tilt-playing-image ul li:nth-child(2){top: 37%;}
    .tilt-playing-image ul li:last-child{top: 65%;}  

}

@media (max-width:280px) {
    .ig-carousel .gallery .continue-block {
        width: 100%;
        min-width: 268px;
        height: 226px;
        bottom: -226px;
        margin: -153px 248px 25px -12px;
        padding: 15px 44px 15px 15px;
    }
}

/*
// IE hacks
//IE 9 and down */
.bg-service-header {
    /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0.11)"; */
}

/*
// IE 10 and 11
*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
    /* .bg-service-header {
        opacity: 0.11; 
        mix-blend-mode: normal;
    } */
    .ig-carousel .gallery .continue-block h2,
    .ig-carousel .gallery .continue-block p {
        color:#000000 !important
    }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
    .html {
        height: 100vh; /* set viewport constraint */
        min-height: 100%; /* enforce height */
    }
    .d-body:after{
      content:"";
      position:fixed; /* stretch a fixed position to the whole screen */
      top:0;
      height:100vh; /* fix for mobile browser address bar appearing disappearing */
      left:0;
      right:0;
      z-index:-1; /* needed to keep in the background */
      /* background: url(https://www.w3schools.com/css/trolltunga.jpg) center center; */
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    .back-main,
    .back-transition
    /* .back-preload  */
    {
        /* background-color: slategrey; */
        display: none;
        /* position: absolute;
        top: 0;
        background-attachment: fixed; */
        /*  background: slategrey;
        border: solid 1px green; */
        /* height: 100%;
        width: 100%; */
        /*  overflow: scroll;
        -webkit-overflow-scrolling: touch; */
        /* background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover; 
        -o-background-size: cover; */
        /*  background-size: 100%;
        -webkit-background-size: 100%;
        -moz-background-size: 100%; 
        -o-background-size: 100%;*/
    }

    .back-transition {
        display: none;
    }
    section {
        background-size: cover;
        background-attachment: scroll
    }

}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  
}