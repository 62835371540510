
@define-mixin noselect {
  /* .noselect & { */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  /* } */
}

span {
  &.sml {
    display: none;
  }
  &.med {
    display: block;
  }
}
@media (max-width: 990px) {
  span {
    &.sml {
      display: block;
    }
    &.med {
      display: none;
    }
  }
}

.square,
.fixed-aspect { /*todo: need to combine*/
  display: block;
  position: relative;
  float: none;
  /* margin-top: 55px; */
  width: unset;
  /* width: 50%; */
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  &.onebyone,
  &.fiftyfivebynineteen,
  &.insightcard,
  &.threefourths,
  &.threebyfour,
  &.threebytwo,
  &.sixteenbynine,
  &.sixteenbyseven,
  &.fourbythree {
    position: relative;
    overflow: hidden;
    height: unset;
    width: 100%;
    & img { /*replaces background-imgfit*/
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  &.onebyone {
    padding-bottom: 100%; /* 1:1 */
  }
  &.threebyfour:after {
      padding-bottom: 133%; /* 3:4 */
  }
  &.insightcard:after {
      padding-bottom: 86.47%; /* Insight Card */
  }
  &.threefourths:after,
  &.fourbythree:after {
      padding-bottom: 75%; /* 4:3 */
  }
  &.threebytwo:after  {
      padding-bottom: 66.66%; /* 3:2 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  &.sixteenbynine:after  {
      padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  &.sixteenbyseven:after  {
      padding-bottom: 43.75%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  &.fiftyfivebynineteen:after  { /*Grid Img*/
      padding-bottom: 74.39%; /* : Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  /* If you want text inside of the container */
  & .textblock {
      position: absolute;
      top: 50%; 
      left: 47%;
      /* margin: auto; */
      bottom: 0;
      /* width: 100%; */
      right: 0;
  }
}
.partners-page,
.move-forward {/* override for approach //TODO: need to fix with above later */
  & .fixed-aspect.onebyone {
    padding-bottom: unset; /* 1:1 */
  }
}
/*in progress*/
:root {
  --sp-mic: 20px;
  --sp-sml: 40px;
  --sp-med: 70px;
  --sp-lrg: 90px;
  --sp-xlrg: 120px;
}
.space-sm {
  &.b,
  &.btm {
    padding-bottom: var(--sp-sml);
  }
  &.t,
  &.top {
    padding-top: var(--sp-sml);
  }
}
.space-md {
  &.b,
  &.btm {
    padding-bottom: var(--sp-med);
  }
  &.t,
  &.top {
    padding-top: var(--sp-med);
  }
}
.space-lg {
  &.b,
  &.btm {
    padding-bottom: var(--sp-lrg);
  }
  &.t,
  &.top {
    padding-top: var(--sp-lrg);
  }
}
.space-xlg {
  &.b,
  &.btm {
    padding-bottom: var(--sp-xlrg);
  }
  &.t,
  &.top {
    padding-top: var(--sp-xlrg);
  }
}

@media (max-width: 1449px) {
/* @media (max-width: 1199px) { */
  .space-sm {
    &.b,
    &.btm {
      padding-bottom: var(--sp-sml);
    }
    &.t,
    &.top {
      padding-top: var(--sp-sml);
    }
  }
  .space-md {
    &.b,
    &.btm {
      padding-bottom: var(--sp-med);
    }
    &.t,
    &.top {
      padding-top: var(--sp-med);
    }
  }
  .space-lg {
    &.b,
    &.btm {
      padding-bottom: var(--sp-lrg);
    }
    &.t,
    &.top {
      padding-top: var(--sp-lrg);
    }
  }
  .space-xlg {
    &.b,
    &.btm {
      padding-bottom: var(--sp-xlrg);
    }
    &.t,
    &.top {
      padding-top: var(--sp-xlrg);
    }
  }
}
@media (max-width: 1199.98px) {
/* @media (max-width: 1199px) { */
  .space-md {
    &.b,
    &.btm {
      padding-bottom: var(--sp-sml);
    }
    &.t,
    &.top {
      padding-top: var(--sp-sml);
    }
  }
  .space-lg {
    &.b,
    &.btm {
      padding-bottom: var(--sp-med);
    }
    &.t,
    &.top {
      padding-top: var(--sp-med);
    }
  }
  .space-xlg {
    &.b,
    &.btm {
      padding-bottom: var(--sp-lrg);
    }
    &.t,
    &.top {
      padding-top: var(--sp-lrg);
    }
  }
}
@media (max-width: 768px) {
  .space-sm {
    &.b,
    &.btm {
      padding-bottom: var(--sp-mic);
    }
    &.t,
    &.top {
      padding-top: var(--sp-mic);
    }
  }
  .space-md {
    &.b,
    &.btm {
      padding-bottom: var(--sp-mic);
    }
    &.t,
    &.top {
      padding-top: var(--sp-mic);
    }
  }
  .space-md {
    &.b,
&.btm {
      padding-bottom: var(--sp-sml);
    }
    &.t,
&.top {
      padding-top: var(--sp-sml);
    }
  }
  .space-lg {
    &.b,
&.btm {
      padding-bottom: var(--sp-sml);
    }
    &.t,
&.top {
      padding-top: var(--sp-sml);
    }
  }
  .space-xlg {
    &.b,
&.btm {
      padding-bottom: var(--sp-med);
    }
    &.t,
&.top {
      padding-top: var(--sp-med);
    }
  }
}
@media (max-width: 575.98px) {
  .space-sm {
    &.b,
    &.btm {
      padding-bottom: var(--sp-mic);
    }
    &.t,
    &.top {
      padding-top: var(--sp-mic);
    }
  }
  .space-md {
    &.b,
    &.btm {
      padding-bottom: var(--sp-mic);
    }
    &.t,
    &.top {
      padding-top: var(--sp-mic);
    }
  }
  .space-lg {
    &.b,
&.btm {
      padding-bottom: var(--sp-sml);
    }
    &.t,
&.top {
      padding-top: var(--sp-sml);
    }
  }
  .space-xlg {
    &.b,
&.btm {
      padding-bottom: var(--sp-sml);
    }
    &.t,
&.top {
      padding-top: var(--sp-sml);
    }
  }
} 
